import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BackButton from "../components/flow/BackButton";
import List from "../components/flow/List";
import FlowHead from "../components/FlowHead";
import LoaderScreen from "../components/LoaderScreen";
import { RequireUserAuth } from "../context/UserContext";
import { useUserContext } from "../context/UserContext";
import { getFlowCloseIcon } from "../services/icons";
import { isDesktopView } from "../services/utils";

function FlowPage() {
  const [loading, setLoading] = useState(false);
  const [runValidate, setRunValidate] = useState(false);
  const navigate = useNavigate();

  const { user } = useUserContext();

  if (loading || !user.info) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      {isDesktopView() && (
        <div
          className="absolute right-[12px] top-[24px] cursor-pointer lg:right-[24px]"
          onClick={() => navigate("/vault")}
          id="flow_close_button"
        >
          {getFlowCloseIcon("#E8E3D2")}
        </div>
      )}

      <div className="relative grow">
        <BackButton />
        <FlowHead
          title={[
            `${user.info.firstName} ${user.info.lastName}`,
            user.info.personNumber,
          ]}
        />
        <List runValidate={runValidate} setRunValidate={setRunValidate} />
      </div>
    </>
  );
}

export default function FlowPageWrapper() {
  return (
    <RequireUserAuth>
      <FlowPage />
    </RequireUserAuth>
  );
}
