type PropsType = {
  title: string;
  wrapperClasses?: string;
};

export default function SectionDivider({
  title,
  wrapperClasses = "",
}: PropsType) {
  return (
    <div
      className={`mb-[15px] border-b-[1px] border-[#CECABA] pb-[5px] font-intermedium text-[12px] uppercase tracking-[0.2em] text-[#736F62] ${wrapperClasses}`}
    >
      {title}
    </div>
  );
}
