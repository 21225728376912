import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoaderScreen from "../components/LoaderScreen";

export default function IndexPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  });
  return <LoaderScreen />;
}
