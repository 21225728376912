import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import config from "./config";
import { FlowProvider } from "./context/FlowContext";
import { UserProvider } from "./context/UserContext";

Sentry.init({
  dsn: config.sentry_dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: config.stage,
  release: process.env.npm_package_name + "@" + process.env.npm_package_version,
  normalizeDepth: 6,
});

const baseurl = config.baseurl;

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <BrowserRouter basename={baseurl}>
    <UserProvider>
      <FlowProvider>
        <App />
      </FlowProvider>
    </UserProvider>
  </BrowserRouter>
);
