import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import GdprRedirect from "./components/GdprRedirect";
import Layout from "./components/Layout";
import LayoutPartner from "./components/LayoutPartner";
import LayoutPublic from "./components/LayoutPublic";
import { useUserContext } from "./context/UserContext";
import AffiliatePage from "./pages/AffiliatePage";
import DebugPage from "./pages/DebugPage";
import DocumentItemPage from "./pages/DocumentItemPage";
import DocumentsPage from "./pages/DocumentsPage";
import EconomyPage from "./pages/EconomyPage";
import FamilyPage from "./pages/FamilyPage";
import FaqVaultPage from "./pages/FaqVaultPage";
import FlowPage from "./pages/FlowPage";
import FlowRestartPage from "./pages/FlowRestartPage";
import IndexPage from "./pages/IndexPage";
import InvitePage from "./pages/InvitePage";
import LoginPage from "./pages/LoginPage";
import NoPage from "./pages/NoPage";
import PaymentPage from "./pages/PaymentPage";
import ProfileEdit from "./pages/ProfileEdit";
import VaultPage from "./pages/VaultPage";
import { isFeatureEnabled } from "./services/feature";

export default function App() {
  const { user, populateFromStorage, saveToStorage } = useUserContext();

  useEffect(() => {
    populateFromStorage();
  }, []);

  useEffect(() => {
    saveToStorage();
  }, [user]);

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<LayoutPublic />}>
          <Route path="/" element={<IndexPage />} />
        </Route>
        <Route element={<LayoutPartner />}>
          <Route path="/partner/:pt?/?" element={<AffiliatePage />} />
        </Route>
        <Route element={<Layout bgOpacity={true} mobileProfileHeader={true} />}>
          <Route path="/vault" element={<VaultPage />} />
        </Route>
        <Route element={<Layout bgOpacity={true} />}>
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/family" element={<FamilyPage />} />
          {isFeatureEnabled("financial-overview") && (
            <Route path="/economy" element={<EconomyPage />} />
          )}
        </Route>
        <Route element={<Layout mobileNoBgImage={true} />}>
          <Route path="/flow" element={<FlowPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/invite" element={<InvitePage />} />

          <Route path="/vault/profile" element={<ProfileEdit />} />
          <Route path="/vault/faq" element={<FaqVaultPage />} />
          <Route path="/documents/:type" element={<DocumentItemPage />} />
          <Route path="/debug" element={<DebugPage />} />
          <Route path="/payment/:data" element={<PaymentPage />} />
          <Route path="/restart" element={<FlowRestartPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/gdpr" element={<GdprRedirect />} />
      </Routes>
    </ErrorBoundary>
  );
}
