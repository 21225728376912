import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import config from "../config";
import { useUserContext } from "../context/UserContext";
import { isFeatureEnabled } from "../services/feature";
import {
  getButtonArrow,
  getCloseIcon,
  getDocumentIcon,
  getEconomyIcon,
  getFamilyTreeIcon,
  getFenixFamilyLogo,
  getLogInIcon,
  getLogOutIcon,
  getVaultLogo,
} from "../services/icons";
import { isDesktopView } from "../services/utils";
import Button from "./Button";
import Modal from "./Modal";
import IconLink from "./ui/IconLink";

const websiteUrl: string = config.website_url;

export default function HeadProfile({ theme }: { theme?: "dark" | "light" }) {
  const [path, setPath] = useState("");
  const [closeWarningModal, setCloseWarningModal] = useState(false);
  const [closeWarningData, setCloseWarningData] = useState("");

  const { user, logout } = useUserContext();
  const loc = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setPath(loc.pathname.replaceAll("/", ""));
  }, [loc]);

  const performNavigate = (to: string, check = true) => {
    if (check && loc.pathname.includes("/contract/")) {
      setCloseWarningModal(true);
      setCloseWarningData(to);
      return;
    }
    setCloseWarningModal(false);
    setCloseWarningData("");

    if (to === "logout") {
      logout();
    } else {
      navigate(to);
    }
  };

  const renderBackButton = (iconsColor: string) => {
    if (isDesktopView()) {
      return null;
    }
    if (["vault", "documents"].includes(path)) {
      return null;
    }

    let id = "flow_close_button";
    let navigateTo = "vault";
    if (path === "documentstestamente") {
      navigateTo = "documents";
      id = "will_doc_close_button";
    }

    if (navigateTo === path) {
      return null;
    }

    return (
      <div
        className="rotate-180 cursor-pointer"
        onClick={() => performNavigate(navigateTo, false)}
        id={id}
      >
        {getButtonArrow(iconsColor, "20", "100%")}
      </div>
    );
  };

  let iconsColor = "#FFFEFB";
  let textColor = "#FFFEFB";
  if (theme === "dark") {
    textColor = "#002733";
    iconsColor = "#002733";
  }

  let iconWidthSize = "20";
  if (!isDesktopView()) {
    iconWidthSize = "16";
  }

  return (
    <div className="my-7 flex w-full flex-row items-end justify-between lg:my-9 lg:flex-row">
      {!isDesktopView() && (
        <div className="flex flex-1">{renderBackButton(iconsColor)}</div>
      )}
      <div className="flex flex-1 flex-row items-end gap-20">
        <div className="mx-auto flex flex-row gap-5 lg:mx-0">
          <a
            onClick={() => {
              window.location.href = websiteUrl;
              return;
            }}
            className="w-[100px] cursor-pointer lg:w-[160px]"
          >
            {getFenixFamilyLogo(iconsColor, "100%", "100%")}
          </a>
        </div>
        {user?.info && (
          <div
            className={`hidden flex-row items-center gap-0 text-[14px] lg:flex ${textColor} lg:gap-6`}
          >
            <IconLink
              icon={getVaultLogo(iconsColor, "20", "100%")}
              onClickHandle={() => performNavigate("/vault")}
              title="hem"
              color={textColor}
            />
            <IconLink
              icon={getDocumentIcon(iconsColor)}
              onClickHandle={() => performNavigate("/documents")}
              title="dokument"
              color={textColor}
            />
            {/*<IconLink*/}
            {/*  icon={getFamilyTreeIcon(iconsColor)}*/}
            {/*  onClickHandle={() => performNavigate("/family")}*/}
            {/*  title="familj"*/}
            {/*  color={textColor}*/}
            {/*/>*/}
            {isFeatureEnabled("financial-overview") && (
              <IconLink
                icon={getEconomyIcon(iconsColor)}
                onClickHandle={() => performNavigate("/economy")}
                title="ekonomi"
                color={textColor}
              />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-1 justify-end">
        {!user?.info && (
          <IconLink
            to="/login"
            icon={getLogInIcon(iconsColor, iconWidthSize, "100%")}
            title="logga in"
            color={textColor}
          />
        )}
        {user?.info && (
          <IconLink
            icon={getLogOutIcon(iconsColor, iconWidthSize, "100%")}
            onClickHandle={() => performNavigate("logout")}
            title="logga ut"
            color={textColor}
            id="flow_logout"
          />
        )}
      </div>

      {closeWarningModal && (
        <Modal title="" onClose={() => setCloseWarningModal(false)}>
          <div className="bg-theme-white p-5 lg:bg-none lg:p-0">
            <div className="mt-3 w-full max-w-[590px]">
              Väljer du att avbryta innan alla frågor är besvarade försvinner
              svaren du angivit. Du är naturligtvis välkommen att börja om med
              ditt avtal närsomhelst.
            </div>
            <div className="mt-7 inline-block">
              <Button
                title="Jag vill avbryta"
                onClick={() => performNavigate(closeWarningData, false)}
                iconComp={getCloseIcon("#ffffff", "16", "100%", "2")}
                icon={{ width: -20 }}
                theme="red"
                rightArrow={false}
              />
              <button
                type="button"
                onClick={() => setCloseWarningModal(false)}
                className="mx-auto my-2 flex items-center gap-2 text-dark-blue"
              >
                <div>
                  <span className="border-b-[1px] border-dark-blue font-intermedium uppercase tracking-widest">
                    Jag vill fortsätta
                  </span>
                </div>
                {getButtonArrow("#0C2632", "18", "100%")}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
