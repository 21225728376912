import React, { useEffect, useState } from "react";

import { useFlowContext } from "../../context/FlowContext";
import { PersonsDistributionValueType } from "../../types/entities";
import { FlowFieldOption, FlowItemFieldInfo } from "../../types/flow";
import ErrorFormMessage from "../ErrorFormMessage";
import QuestionTitle from "../flow/QuestionTitle";
import Number from "./Number";

type PropsType = {
  name: string;
  options: FlowFieldOption[];
  onChange: (id: string, val?: number) => void;
  wrapperClasses?: string;
  value?: PersonsDistributionValueType[];
  label?: string;
  optionClasses?: string;
  labelClasses?: string;
  error?: string;
  info?: FlowItemFieldInfo;
  desc?: string;
};

export default function CheckboxDistribution({
  name,
  label,
  options,
  wrapperClasses = "",
  labelClasses = "",
  optionClasses = "",
  onChange,
  value,
  error,
  info,
  desc,
}: PropsType) {
  const [allowAdd, setAllowAdd] = useState(true);
  const { replaceValuesInTitle } = useFlowContext();

  useEffect(() => {
    checkTotalSum();
  }, [value]);

  if (error) {
    labelClasses += " text-red-500";
    // optionClasses += " text-red-500";
  }

  const updateValue = (val: string) => {
    onChange(val);
    checkTotalSum();
  };

  const updateNumber = (val: string, num: number) => {
    onChange(val, num);
    checkTotalSum();
  };

  const checkTotalSum = () => {
    const sum = value
      ? value.reduce(
          (s, v) => s + Object.values(v).reduce((a, i) => a + i, 0),
          0
        )
      : 0;

    setAllowAdd(sum < 100);
  };

  return (
    <>
      <QuestionTitle
        label={label}
        cssClasses={labelClasses}
        info={info}
        name={name}
      />
      <div
        className={`flex flex-col gap-4 ${wrapperClasses}`}
        data-testid="checkbox-distribution"
      >
        {desc && (
          <div
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        )}
        {options.map((el) => {
          const elValue = value?.find((v) => Object.keys(v).includes(el.value));
          return (
            <div
              key={el.value}
              className={`relative rounded-[4px] border-[1px] border-theme-neutral3 p-2 ${
                elValue ? "bg-neutral-yellow" : ""
              }`}
            >
              <div className="flex items-start gap-1">
                <label
                  className="relative flex cursor-pointer items-center rounded-full p-1"
                  htmlFor={`${name}-${el.value}`}
                >
                  <input
                    id={`${name}-${el.value}`}
                    data-testid={`${name}-${el.value}`}
                    type="checkbox"
                    value={el.value}
                    name={`${name}-${el.value}`}
                    onChange={(e) => updateValue(e.currentTarget.value)}
                    checked={elValue !== undefined}
                    style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                    className={`before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-full border border-[#736F62] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-6 before:w-6 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-theme-primary3 checked:before:bg-[#6F86A0] hover:before:opacity-10`}
                  />
                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-theme-primary3 opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.1246 8.94478C17.4623 8.50777 17.3818 7.87974 16.9448 7.54205C16.5078 7.20436 15.8797 7.28487 15.5421 7.72189L10.6082 14.1069L8.40996 11.6644C8.0405 11.2539 7.40821 11.2206 6.9977 11.59C6.58719 11.9595 6.55391 12.5918 6.92337 13.0023L9.92337 16.3356C10.1218 16.5561 10.4078 16.6771 10.7041 16.666C11.0005 16.6549 11.2766 16.5128 11.458 16.2781L17.1246 8.94478Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </label>
                <label
                  htmlFor={`${name}-${el.value}`}
                  className={`cursor-pointer select-none self-center pt-[2px] font-internormal text-[13px] leading-[16px] text-dark-blue ${optionClasses}`}
                >
                  {replaceValuesInTitle(el.label)}
                </label>
              </div>
              {elValue && (
                <div className="my-4 flex flex-row items-center justify-center">
                  <Number
                    startValue={elValue ? elValue[el.value] : 0}
                    maxValue={100}
                    minValue={0}
                    buttonSize={42}
                    onChange={(number) => updateNumber(el.value, number)}
                    percentage={true}
                    wrapperClasses="w-[240px]"
                    allowAdd={allowAdd}
                  />
                </div>
              )}
            </div>
          );
        })}
        {!allowAdd && (
          <div className="px-3 font-internormal text-[13px] italic">
            Du har nått 100%! För att omfördela procent så frigör du andelar
            genom att klicka minus på de arvingar du önskar omfördela från.
          </div>
        )}
      </div>
      {error ? <ErrorFormMessage message={error} /> : null}
    </>
  );
}
