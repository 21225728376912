import React, { useEffect, useState } from "react";

import LoaderScreen from "../components/LoaderScreen";
import SectionDivider from "../components/SectionDivider";
import VaultHead from "../components/VaultHead";
import { useFlowContext } from "../context/FlowContext";
import { RequireUserAuth, useUserContext } from "../context/UserContext";
import { assetsTypes } from "../services/flow";
import { getEconomyIcon } from "../services/icons";

function EconomyPage() {
  const [loading, setLoading] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [assets, setAssets] = useState();

  const { user } = useUserContext();
  const { flow } = useFlowContext();

  useEffect(() => {
    setSectionTitle("tillgångar och skulder");
  }, []);

  useEffect(() => {
    console.log(flow.assets);
    const ass = flow.assets.reduce((acc, item) => {
      const i = {
        type: item.type,
        id: item.id,
        value: 0,
      };
      return [...acc, i];
    }, []);

    console.log(ass);
  }, [flow.assets]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <VaultHead title="Din ekonomi" icon={getEconomyIcon("#0C2632")} />
      <div>
        <SectionDivider title={sectionTitle} wrapperClasses="!mb-6" />
      </div>

      <div className="flex flex-row gap-10">
        <div className="grid w-full flex-1 grid-cols-2 gap-1">
          {assetsTypes.map((a) => (
            <div
              key={a.type}
              className="flex flex-col items-center justify-center gap-3 rounded-[4px] bg-[#F3F0E6] pt-10 pb-5 leading-none text-theme-neutral4"
            >
              <div className="mb-1">{a.icon("#736F62", "100%", "40px")}</div>
              <div className="font-intermedium text-[12px] uppercase tracking-[2.4px]">
                {a.label}
              </div>
              <div className="font-interlight">1111111 kr</div>
            </div>
          ))}
          <div className="col-span-2 flex flex-col gap-3 rounded-[4px] bg-theme-neutral1 px-10 py-5 text-theme-neutral4">
            <div className="flex flex-row items-center justify-between px-2">
              <div className="font-intermedium text-[13px]">Tillgångar</div>
              <div className="font-interlight text-[18px]">24 880 000 kr</div>
            </div>
            <div className="h-[1px] w-full border-b-[1px] border-theme-neutral4" />
            <div className="flex flex-row items-center justify-between px-2">
              <div className="font-intermedium text-[13px]">Skulder</div>
              <div className="font-interlight text-[18px] text-theme-func-red">
                24 880 000 kr
              </div>
            </div>
          </div>
          <div className="col-span-2 rounded-[4px] bg-theme-neutral2 px-10 py-5 text-theme-neutral4">
            <div className="flex flex-row items-center justify-between">
              <div className="font-intermedium text-[13px]">Totalt</div>
              <div className="font-intermedium text-[18px] text-theme-neutral4">
                = 24 880 000 kr
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="table w-full table-fixed rounded-[4px] border-[1px] border-theme-neutral1 bg-theme-white text-[12px] text-theme-primary1">
            <div className="table-row bg-theme-neutral1 font-intermedium leading-none tracking-[2.4px]">
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px] uppercase">
                tillgång
              </div>
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px] uppercase">
                detaljer
              </div>
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px] uppercase">
                värde
              </div>
              <div className="table-cell border-b-[1px] border-theme-neutral3 px-[8px] py-[12px] uppercase">
                Skuld
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                tillgång
              </div>
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                detaljer
              </div>
              <div className="table-cell border-b-[1px] border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                värde
              </div>
              <div className="table-cell border-b-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                Skuld
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                tillgång
              </div>
              <div className="table-cell border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                detaljer
              </div>
              <div className="table-cell border-r-[1px] border-theme-neutral3 px-[8px] py-[12px]">
                värde
              </div>
              <div className="table-cell px-[8px] py-[12px]">Skuld</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function EconomyPageWrapper() {
  return (
    <RequireUserAuth>
      <EconomyPage />
    </RequireUserAuth>
  );
}
