import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import FlowRestart from "../components/FlowRestart";
import LegalTeamSection from "../components/LegalTeamSection";
import LoaderScreen from "../components/LoaderScreen";
import VaultHead from "../components/VaultHead";
import VaultHero from "../components/VaultHero";
import VaultLink from "../components/VaultLink";
import config from "../config";
import { useFlowContext, useFlowDispatchContext } from "../context/FlowContext";
import { pushUserDataToStorage } from "../context/helpers/flow";
import { RequireUserAuth } from "../context/UserContext";
import { useUserContext } from "../context/UserContext";
import {
  getAlignLeftIcon,
  getGearsIcon,
  getQuestionIcon,
  getRestartIcon,
  getUsersIcon,
  getVaultLogo,
} from "../services/icons";
import { getCookie, ppCampKey, removeCookie } from "../services/storage";
import { decodeBase64 } from "../services/utils";
import { FlowPPCampType } from "../types/flow";
import { UserPackageType } from "../types/user";

function VaultPage() {
  const [loading, setLoading] = useState(true);
  const [showLegalTeam, setShowLegalTeam] = useState(false);
  const [packageData, setPackageData] = useState<UserPackageType>();
  const navigate = useNavigate();
  const packageInterval = useRef<NodeJS.Timer | null>(null);
  const { user, fetchPackageData } = useUserContext();
  const { flow, fetchFlowData, restartFlow, setPackageOrInvitation } =
    useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    if (!user.info) {
      navigate("/login");
      return;
    }
    if (user.accessToken) {
      const packagePromise = fetchPackageData(user.accessToken);
      const flowPromise = fetchFlowData(user.accessToken);

      void Promise.allSettled([packagePromise, flowPromise])
        .then((results) => {
          const [packageRes, flowRes] = results;
          const updatedFlow = flowRes.value ? flowRes.value : { ...flow };

          const flowWithPackage = setPackageOrInvitation(
            flowRes.value?.pkg,
            flowRes.value?.inv
          );
          updatedFlow.pkg = flowWithPackage.pkg;
          if (flowWithPackage.inv) {
            updatedFlow.inv = flowWithPackage.inv;
          }

          if (packageRes.value?.status === "Active") {
            dispatchFlow({
              type: "update-payment-status",
              payload: { paymentStatus: false },
            });
            updatedFlow.paymentStatus = false;
          }

          pushUserDataToStorage(user.accessToken, updatedFlow);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    setPackageData(user.package);
  }, [user]);

  useEffect(() => {
    if (!flow.paymentStatus || !user.accessToken) {
      return;
    }

    packageInterval.current = setInterval(() => {
      if (!user.accessToken) {
        return;
      }
      void fetchPackage(user.accessToken);
    }, 5000);

    return () => {
      if (packageInterval.current) {
        clearInterval(packageInterval.current);
      }
    };
  }, [flow.paymentStatus, user.accessToken]);

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }

    const ppCampData = getCookie(ppCampKey);
    if (ppCampData) {
      try {
        const dt = JSON.parse(decodeBase64(ppCampData)) as FlowPPCampType;
        dispatchFlow({
          type: "set-ppcamp-data",
          payload: { accessToken: user.accessToken, ppCamp: dt },
        });
        removeCookie(ppCampKey);
      } catch (e) {
        return console.log(e);
      }
    }
  }, [user.accessToken]);

  const fetchPackage = (accessToken: string) => {
    return fetchPackageData(accessToken).then((data) => {
      if (data && data.status === "Active" && user.accessToken) {
        dispatchFlow({
          type: "update-payment-status",
          payload: { accessToken: user.accessToken, paymentStatus: false },
        });
      }
    });
  };

  const onClickSupportHandle = () => {
    if (window.SI_API) {
      window.SI_API.setChatInfo(
        user.profile.firstName + " " + user.profile.lastName,
        user.profile.email,
        user.profile?.phoneNumber,
        "kundapp"
      );
      window.SI_API.showPopup();
    }
  };

  const renderTempRestartButton = () => {
    if (config.stage === "production") {
      return null;
    }
    return (
      <>
        <hr className="my-14" />
        <div className="opacity-60">
          <FlowRestart hardReset={true} />
        </div>
      </>
    );
  };

  if (loading || !user.info) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <VaultHead
        title="Ditt kassaskåp"
        icon={getVaultLogo("#0C2632", "22px", "100%")}
      />

      {showLegalTeam && (
        <div>
          <LegalTeamSection
            onCloseSection={() => setShowLegalTeam(false)}
            onContactHandler={onClickSupportHandle}
          />
        </div>
      )}
      {!showLegalTeam && (
        <>
          <VaultHero packageData={packageData} />
          <div className="mt-10 grid grid-cols-1 gap-5 lg:mt-20 lg:grid-cols-2">
            <div>
              <VaultLink
                title="Frågor och svar"
                subtitle="Svar på frågor vi får ofta"
                onClickHandle={() => navigate("/vault/faq")}
                leftIcon={getAlignLeftIcon("#0C2632", "18", "100%")}
              />
            </div>
            <div>
              <VaultLink
                title="Våra jurister"
                subtitle="Bakom vår digitala tjänst finns riktiga människor med juridisk kompetens"
                onClickHandle={() => setShowLegalTeam(true)}
                leftIcon={getUsersIcon("#0C2632", "19", "100%")}
              />
            </div>
            <div>
              <VaultLink
                title="Mitt konto"
                subtitle="Inloggningsuppgifter och prenumeration"
                onClickHandle={() => navigate("/vault/profile")}
                leftIcon={getGearsIcon("#0C2632", "20", "100%")}
              />
            </div>
            <div>
              <VaultLink
                title="Support"
                subtitle="Stött på problem?"
                onClickHandle={onClickSupportHandle}
                leftIcon={getQuestionIcon("#0C2632", "20", "100%")}
              />
            </div>
          </div>

          {renderTempRestartButton()}
        </>
      )}
    </>
  );
}

export default function VaultPageWrapper() {
  return (
    <RequireUserAuth>
      <VaultPage />
    </RequireUserAuth>
  );
}
