import React, { useLayoutEffect, useRef, useState } from "react";

type FlowHeadProps = {
  title: string | string[];
};

export default function FlowHead({ title }: FlowHeadProps) {
  const [brakeLike, setBrakeLine] = useState(false);
  const contRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (!contRef.current || !textRef.current) {
      return;
    }
    if (
      contRef.current.clientWidth === textRef.current.clientWidth ||
      contRef.current.clientWidth - textRef.current.clientWidth <= 20
    ) {
      setBrakeLine(true);
      return;
    }
    setBrakeLine(false);
  }, [contRef, textRef]);

  const renderTitle = () => {
    if (typeof title === "string") {
      return title;
    }
    if (Array.isArray(title)) {
      if (brakeLike) {
        return title.map((el) => <div key={el.length}>{el}</div>);
      }
      return title.join(" | ");
    }
  };

  return (
    <div className="pl-5 pt-[4px] lg:pl-0">
      <div
        ref={contRef}
        className="mb-4 font-intermedium text-[12px] uppercase tracking-[0.25em] text-[#736F62] lg:mb-10 lg:text-[16px]"
      >
        <span className="inline-block" ref={textRef}>
          {renderTitle()}
        </span>
      </div>
    </div>
  );
}
