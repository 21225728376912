type FaqType = {
  question: string;
  answer: string;
  linkTitle?: string;
  link?: string;
};

export const advisors = [
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Johanna.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Johanna.jpg",
      import.meta.url
    ),
    name: "Johanna Blumenthal",
    title: "Chefsjurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Johan.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Johan.jpg",
      import.meta.url
    ),
    name: "Johan Henriksson",
    title: "Gruppledare/Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Sanna.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Sanna.jpg",
      import.meta.url
    ),
    name: "Sanna Wetterin",
    title: "Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Anima.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Anima.jpg",
      import.meta.url
    ),
    name: "Anima Karim",
    title: "Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Erik.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Erik.jpg",
      import.meta.url
    ),
    name: "Erik Johnsson-Arvids",
    title: "Administratör",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/My.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/My.jpg",
      import.meta.url
    ),
    name: "My Blomster",
    title: "Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Anna.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Anna.jpg",
      import.meta.url
    ),
    name: "Anna Blomgren",
    title: "Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Emmy.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Emmy.jpg",
      import.meta.url
    ),
    name: "Emmy Forss Hellstrand",
    title: "Jurist",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Maria.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Maria.jpg",
      import.meta.url
    ),
    name: "Maria Antoniadou",
    title: "Dödsboförvaltare",
  },
  {
    image: new URL(
      "../assets/images/advisors/JPG_195x235/Angelica.jpg",
      import.meta.url
    ),
    imageRetina: new URL(
      "../assets/images/advisors/JPG_390x470/Angelica.jpg",
      import.meta.url
    ),
    name: "Angelika Jansson",
    title: "Dödsboförvaltare",
  },
];

export const faq: FaqType[] = [
  {
    question: "Måste jag skriva ett testamente?",
    answer:
      "Nej, det måste du inte. Utan testamente kommer arvet efter dig att fördelas enligt lag till dina närmaste släktingar och/eller din make/maka. Detta kan dock innebära att dina personliga önskemål inte uppfylls. Du kanske vill att en sambo, kompis, idrottsförening eller annan släkting ska ha en del av ditt arv. Då krävs ett testamente.",
  },
  {
    question: "Kan jag testamentera bort allt precis som jag vill?",
    answer:
      "Ja, det kan du. Men det är viktigt att du vet om att ifall du har barn utanför ett nuvarande äktenskap, så har de alltid rätt att få ut sin laglott från din kvarlåtenskap. Laglotten är hälften av vad de hade fått om du inte skrivit ett testamente. Detta betyder att du fritt kan testamentera 100% av din kvarlåtenskap, men har du barn utanför ett nuvarande äktenskap kan de begära jämkning av testamentet så att upp till 50% av det du testamenterat bort istället går till det/dem.",
  },
  {
    question: "Måste jag tala om för någon att jag skrivit ett testamente?",
    answer:
      "Du behöver ha två personer som deltar vid signeringen och skriftligen bevittnar ditt testamente. Dessa två ska vara utomstående på så vis att de inte får påverkas av testamentet eller vara släkt med dig. Du behöver inte delge innehållet i testamentet, men du måste berätta att de är vittnen till ditt testamente. I övrigt behöver du inte tala om det för någon. Det kan också vara en bra idé att placera testamentet på en plats så att dina efterlevande hittar det samt att ha en kopia hos en neutral tredjepart, till exempel hos oss på Fenix Family. Vi kan förvara testamentet säkert, både digitalt och fysiskt, och meddela dina efterlevande när du går bort.",
  },
  {
    question: "Vad händer om jag ändrar mig innan jag går bort?",
    answer:
      "Då kan du skriva ett tillägg till testamentet om det är en mindre justering. Kom ihåg att även tillägg måste följa samma formkrav som ett ordinarie testamente. \n\nVid större justeringar rekommenderar vi att du skriver ett helt nytt testamente som ersätter det gamla.",
  },
  {
    question:
      "Vad händer om jag inte har kvar de tillgångar som jag testamenterar bort när jag avlider?",
    answer:
      'Har du inte kvar specifika tillgångar, så händer ingenting. De kan inte fördelas och ersätts inte med något annat. Vill du vara säker på att någon specifik arvinge erhåller en viss andel, rekommenderar vi att du istället för specifik egendom utger ett universellt förordnande. Ett exempel på detta är "1/4 av min kvarlåtenskap".',
  },
  {
    question: "Varför behöver jag en framtidsfullmakt?",
    answer:
      "Om du skulle bli oförmögen att hantera dina egna intressen, exempelvis om du skulle bli dement, kan den person du utsett fortsätta att ta hand om din ekonomi och person på det sätt du själv skulle ha gjort. Personen företräder dig gentemot bank, myndighet, mäklare etc. En framtidsfullmakt hos en för dig betrodd person är ofta att föredra framför att få en god man tilldelad sig.",
  },
  {
    question: "Vad händer om jag inte har en framtidsfullmakt?",
    answer:
      "I det fall du skulle bli oförmögen att hantera dina egna intressen, exempelvis om du skulle bli dement, kommer i så fall en god man istället utses för att företräda dig. Det blir en person du inte själv valt och processen att utse den goda mannen är tidskrävande. En framtidsfullmakt börjar gälla direkt.",
  },
  {
    question: "Kan jag ha flera fullmaktshavare?",
    answer:
      "Ja. Du kan även välja om de måste agera ihop eller om de kan utföra ärenden var och en för sig, utan den andres godkännande.",
  },
  {
    question: "Behöver fullmaktshavarna veta att jag utsett dem?",
    answer:
      "Det är inget krav att de vet men eftersom det är ett stort ansvar och uppdrag att vara fullmaktshavare, bör de ha blivit informerade och tillfrågade. Så att du kan vara säker på  att de kommer vilja ta sig an uppdraget.",
  },
  {
    question: "Gäller framtidsfullmakten även när jag dött?",
    answer:
      "Det är inte helt glasklart ännu, men praxis pekar på att den kan gälla en tid efter dödsfallet om man skriver in i fullmakten att den ska göra det.",
  },
];
