import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUserContext } from "../context/UserContext";
import {
  getDocumentIcon,
  getEconomyIcon,
  getFamilyTreeIcon,
  getGearsIcon,
  getVaultLogo,
} from "../services/icons";
import { isDesktopView } from "../services/utils";
import IconLink from "./ui/IconLink";

export default function BottomNavigation() {
  const [path, setPath] = useState("");
  const { user, logout } = useUserContext();
  const loc = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setPath(loc.pathname.replaceAll("/", ""));
  }, [loc]);

  const performNavigate = (to: string, check = true) => {
    if (to === "logout") {
      logout();
    } else {
      navigate(to);
    }
  };

  const getColor = (url: string) => {
    if (url === path) {
      return "#0C2632";
    }
    return "#6F86A0";
  };

  if (isDesktopView() || !user?.info) {
    return null;
  }

  return (
    <div className="fixed left-0 bottom-0 z-10 w-full">
      <div className="px-3">
        <div className="grid grid-cols-3 gap-3 rounded-tr-[8px] rounded-tl-[8px] bg-theme-white py-4 px-3 text-[14px] drop-shadow">
          <IconLink
            icon={getVaultLogo(getColor("vault"), "100%", "20")}
            onClickHandle={() => performNavigate("/vault")}
            title="hem"
            color={getColor("vault")}
            vertical={true}
            isActive={path === "vault"}
          />
          <IconLink
            icon={getDocumentIcon(getColor("documents"), "100%", "20")}
            onClickHandle={() => performNavigate("/documents")}
            title="dokument"
            color={getColor("documents")}
            vertical={true}
            isActive={path === "documents"}
          />
          <IconLink
            icon={getGearsIcon(getColor("vaultprofile"), "100%", "20")}
            onClickHandle={() => performNavigate("/vault/profile")}
            title="mitt konto"
            color={getColor("vaultprofile")}
            vertical={true}
            isActive={path === "vaultprofile"}
          />
          {/*<IconLink*/}
          {/*  icon={getFamilyTreeIcon(iconsColor, "100%", "20")}*/}
          {/*  onClickHandle={() => performNavigate("/family")}*/}
          {/*  title="familj"*/}
          {/*  color={textColor}*/}
          {/*  vertical={true}*/}
          {/*  isActive={path === "family"}*/}
          {/*/>*/}
          {/*<IconLink*/}
          {/*  icon={getEconomyIcon(iconsColor, "100%", "20")}*/}
          {/*  onClickHandle={() => performNavigate("/economy")}*/}
          {/*  title="ekonomi"*/}
          {/*  color={textColor}*/}
          {/*  vertical={true}*/}
          {/*  isActive={path === "economy"}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
}
