import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { useUserContext } from "../context/UserContext";
import { isFeatureEnabled } from "../services/feature";
import { getPencilIcon } from "../services/icons";
import { isDesktopView } from "../services/utils";
import BottomNavigation from "./BottomNavigation";
import HeadProfile from "./HeadProfile";
import ReportFeedback from "./ReportFeedback";

export default function Layout({
  bgOpacity = false,
  mobileNoBgImage = false,
  mobileProfileHeader = false,
}) {
  const [hideImage, setHideImage] = useState(false);
  const [profileHeader, setProfileHeader] = useState(false);

  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.accessToken || !user.profile) {
      return;
    }
    if (window.SI_API) {
      window.SI_API.setChatInfo(
        user.profile.firstName + " " + user.profile.lastName,
        user.profile.email,
        user.profile?.phoneNumber,
        "kundapp"
      );
    }
  }, [user.accessToken, user.profile]);

  useEffect(() => {
    if (!isDesktopView() && mobileNoBgImage) {
      setHideImage(true);
    } else {
      setHideImage(false);
    }

    if (!isDesktopView() && mobileProfileHeader) {
      setProfileHeader(true);
    } else {
      setProfileHeader(false);
    }
  }, [mobileNoBgImage, mobileProfileHeader]);

  let cssContentContainer = "";

  let opacityClass = "";
  let contStyle = { backgroundSize: "cover" };
  if (bgOpacity) {
    opacityClass = "bg-opacity-[85%]";
  }
  if (!isDesktopView()) {
    cssContentContainer += " pb-24";
    // contStyle = { backgroundSize: "auto 100vh" };
  }

  if (hideImage) {
    contStyle = { background: "none" };
    opacityClass += " !px-0";
  }

  const renderUserIcon = () => {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.25"
          y="1.25"
          width="57.5"
          height="57.5"
          rx="28.75"
          fill="#CECABA"
          stroke="#FFFEFB"
          strokeWidth="2.5"
        />
        <path
          d="M36.1875 21.75C36.1875 24.8911 33.6411 27.4375 30.5 27.4375C27.3589 27.4375 24.8125 24.8911 24.8125 21.75C24.8125 18.6089 27.3589 16.0625 30.5 16.0625C33.6411 16.0625 36.1875 18.6089 36.1875 21.75Z"
          stroke="#FFFEFB"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M19.5625 42.0938C19.5625 37.3821 23.3821 33.5625 28.0938 33.5625H32.9062C37.6179 33.5625 41.4375 37.3821 41.4375 42.0938C41.4375 43.6643 40.1643 44.9375 38.5938 44.9375H22.4062C20.8357 44.9375 19.5625 43.6643 19.5625 42.0938Z"
          stroke="#FFFEFB"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <main
      className="relative flex flex-grow flex-col bg-theme-white bg-main bg-[center_top] bg-no-repeat"
      style={{ backgroundAttachment: "fixed", ...contStyle }}
    >
      {!hideImage && (
        <div className="absolute top-0 h-[80px] w-full bg-gradient-to-b from-[#0000008a] to-transparent lg:h-[200px]"></div>
      )}

      <div
        className={`relative flex flex-grow flex-col px-3 lg:px-6 ${cssContentContainer}`}
      >
        <div className="2xl:container 2xl:mx-auto">
          {hideImage && <HeadProfile theme="dark" />}
          {!hideImage && <HeadProfile />}
        </div>
        {profileHeader && user?.info && (
          <div
            className="mb-5 flex flex-col items-center gap-1 font-intermedium text-theme-white"
            onClick={() => navigate("/vault/profile")}
          >
            <div className="mb-3">{renderUserIcon()}</div>
            <div className="flex flex-row items-center gap-2 uppercase tracking-[0.25em]">
              <div>
                {user.info.firstName} {user.info.lastName}
              </div>
              <div>{getPencilIcon("#FFFEFB", "16", "100%")}</div>
            </div>
            <div>{user.info.personNumber}</div>
          </div>
        )}
        <div
          className={`${
            hideImage ? "container-flow-shadow" : "container-shadow"
          } relative flex flex-grow flex-col rounded-[16px] 2xl:container 2xl:mx-auto`}
        >
          <div
            className={`relative z-10 flex flex-grow flex-col rounded-[16px] bg-[#FFFEFB] px-3 py-5 lg:px-12 lg:py-6 xl:py-12 xl:px-24 ${opacityClass}`}
          >
            <Outlet />
          </div>
        </div>
        <div className="flex items-center justify-center gap-5 py-5 text-theme-white 2xl:container 2xl:mx-auto">
          <div className="text-center font-interlight">
            © Fenix Family AB {new Date().getFullYear()}
          </div>
          {isFeatureEnabled("error-reporting") && <ReportFeedback />}
        </div>
        <BottomNavigation />
      </div>
    </main>
  );
}
