import { useEffect, useState } from "react";

import {
  useFlowContext,
  useFlowDispatchContext,
} from "../../context/FlowContext";
import { flowSections } from "../../services/flow";
import { isLPAEnabled, isPreplanEnabled } from "../../services/utils";
import { FlowActionSetEdit, FlowSection } from "../../types/flow";

type PropsType = {
  wrapperClasses?: string;
};

type SectionType = FlowSection & { reachable?: boolean };

export default function FlowSections({ wrapperClasses = "" }: PropsType) {
  const [sections, setSections] = useState([] as SectionType[]);
  const { flow } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    const currentSection = flow.section;
    const sections = flowSections.filter((i) => {
      if (i.id === 4) {
        return isLPAEnabled(flow);
      }

      if (i.id === 5) {
        return isPreplanEnabled(flow);
      }

      if (i.type === "main") {
        return true;
      }

      return false;
    });

    const currentIndex = sections.findIndex((s) => s.id === currentSection);
    let indexSet = [];
    if (currentIndex === 0) {
      indexSet = [currentIndex, currentIndex + 1, currentIndex + 2];
    } else if (currentIndex === sections.length - 1) {
      indexSet = [currentIndex - 2, currentIndex - 1, currentIndex];
    } else {
      indexSet = [currentIndex - 1, currentIndex, currentIndex + 1];
    }

    const displaySections = [] as SectionType[];
    indexSet.map((i) => {
      const sec: SectionType = sections[i];
      if (!sec) {
        return;
      }
      const answersBySection = flow.values.filter((v) => v.section === sec.id);
      sec.reachable =
        answersBySection.length > 0 &&
        sec.id !== currentSection &&
        !["edit", "edit-finished", "set-edit-finished"].includes(flow.status);
      displaySections.push(sec);
    });

    setSections(displaySections);
  }, [flow.section, flow.set, flow.setIndex, flow.status]);

  const onSectionSelect = (sectionId: number) => {
    const section = sections.find((s) => s.id === sectionId);
    if (!section || !section.reachable) {
      return;
    }

    const action = {
      type: "set-edit",
      payload: {
        section: section.id,
        set: 1,
        setIndex: 0,
        status: "summary",
      },
    } as FlowActionSetEdit;

    if (!flow.prevPosition) {
      action.payload.prevPosition = {
        section: flow.section,
        set: flow.set,
        setIndex: flow.setIndex,
        status: flow.status,
      };
    }

    dispatchFlow(action);
  };

  if (flow.section > 5) {
    return null;
  }

  return (
    <div className={`relative ${wrapperClasses}`}>
      <div className="absolute top-[4px] z-[-1] hidden h-[1px] w-[calc(100%)] bg-[#CECABA] lg:block" />
      <div
        className={`relative flex w-full flex-col gap-3 lg:flex-row lg:justify-between lg:gap-4`}
      >
        <div className="absolute left-[4px] z-[-1] mt-[5px] h-[calc(100%-10px)] w-[1px] bg-[#CECABA] lg:hidden" />
        {sections.map((section, index) => {
          return (
            <div
              key={`${section.title}-${index}`}
              className={`flex items-center justify-start gap-2 lg:flex-col ${
                index === 0 ? "lg:items-start" : ""
              } ${index === 2 ? "lg:items-end" : ""}`}
            >
              <div
                className={` h-[9px] w-[9px] rounded-full border-[1px] border-white ${
                  section.id === flow.section ? "bg-[#736F62]" : "bg-[#CECABA]"
                } ${section.id >= 2 ? "lg:ml-[20px]" : ""}`}
              ></div>
              <div
                className={`font-intermedium text-[12px] uppercase tracking-[0.2em] transition ${
                  section.id === flow.section
                    ? "text-[#736F62]"
                    : "text-[#CECABA]"
                } ${
                  section.reachable ? "cursor-pointer hover:text-[#736F62]" : ""
                }`}
                onClick={() => onSectionSelect(section.id)}
              >
                {section.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
