import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowDispatchContext } from "../context/FlowContext";
import { useUserDispatchContext } from "../context/UserContext";

export default function InvitePage() {
  const userDispatch = useUserDispatchContext();
  const dispatch = useFlowDispatchContext();
  const navigate = useNavigate();

  useEffect(() => {
    const invitationId = new URLSearchParams(window.location.search).get(
      "invitationId"
    );
    const partnerPackageId = new URLSearchParams(window.location.search).get(
      "partnerPackageId"
    );
    if (invitationId) {
      dispatch({
        type: "set-invitation-id",
        payload: {
          i: invitationId,
          p: partnerPackageId ? parseInt(partnerPackageId) : 0,
        },
      });
    }
    navigate("/login");
  }, []);

  return null;
}
