import React from "react";

import { getButtonArrow } from "../services/icons";

type PropsType = {
  title: string;
  leftIcon: React.ReactNode;
  onClickHandle: () => void;
  subtitle?: string;
};
export default function VaultLink({
  title,
  leftIcon,
  onClickHandle,
  subtitle,
}: PropsType) {
  return (
    <div
      className="flex cursor-pointer flex-row gap-3.5 rounded-[4px] bg-theme-white px-3.5 py-5"
      onClick={onClickHandle}
    >
      <div className="shrink-0">{leftIcon}</div>
      <div className="flex grow flex-col gap-1.5 leading-none">
        <div
          data-testid="vaultlinktitle"
          className="font-intermedium text-theme-primary1"
        >
          {title}
        </div>
        {subtitle && (
          <div className="font-interlight text-[13px] text-theme-neutral4">
            {subtitle}
          </div>
        )}
      </div>
      <div className="shrink-0">{getButtonArrow("#0C2632", "20", "100%")}</div>
    </div>
  );
}
