type PropsType = {
  value: number;
  theme?: "flow" | "document";
  wrapperClasses?: string;
};

export default function ProgressBar({
  value,
  theme = "flow",
  wrapperClasses = "",
}: PropsType) {
  const renderBar = () => {
    if (theme === "flow") {
      return (
        <>
          <div
            className="h-[5px] rounded-full bg-[#736F62] transition-all"
            style={{ width: `${value}%` }}
          />
          <div className="shrink-0 font-intermedium text-[12px] tracking-[0.2em] text-[#736F62]">
            {value}%
          </div>
        </>
      );
    }
    if (theme === "document") {
      return (
        <>
          <div className="relative w-full">
            <div className="z-0 h-[5px] w-full rounded-full bg-[#D81000]" />
            <div
              className="absolute top-0 left-0 z-10 h-[5px] rounded-l-full bg-[#A0C1AB] transition-all"
              style={{ width: `${value}%` }}
            />
          </div>
          <div className="shrink-0 font-intermedium text-[12px] tracking-[0.2em] text-[#D81000]">
            {value}%
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className={`flex flex-row items-center gap-[6px] ${wrapperClasses}`}>
      {renderBar()}
    </div>
  );
}
