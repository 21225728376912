import * as Sentry from "@sentry/react";
import React, { useState } from "react";

import { useUserContext } from "../context/UserContext";
import useLogError from "../hooks/useLogError";
import Button from "./Button";
import Modal from "./Modal";

export default function ReportFeedback() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const { user } = useUserContext();
  const { logMessage } = useLogError();

  const onCloseHandle = () => {
    setMessage("");
    setShowModal(false);
  };

  const onSubmitReport = () => {
    if (!user.info || message.length === 0) {
      return;
    }
    setLoading(true);

    const eventId = Sentry.lastEventId();
    const userFeedback = {
      event_id: eventId ? eventId : logMessage("User Feedback"),
      name: `${user.info.firstName} ${user.info.lastName}`,
      email: user.profile?.email ? user.profile.email : "",
      comments: message,
    };

    Sentry.captureUserFeedback(userFeedback);
    onCloseHandle();
  };

  return (
    <>
      <div
        className="flex cursor-pointer flex-row items-center gap-1.5"
        onClick={() => setShowModal(!showModal)}
      >
        <div className="flex h-[16px] w-[16px] items-center justify-center rounded-full border-[1px] text-[12px] leading-none">
          !
        </div>
        <div>Rapportera fel</div>
      </div>
      {showModal && (
        <Modal onClose={onCloseHandle} title="">
          <div className="bg-theme-white p-5 lg:min-w-[440px] lg:p-0">
            <label
              className="mb-[10px] block font-intermedium text-dark-blue"
              htmlFor="message"
            >
              Rapportera fel
            </label>
            <textarea
              name="message"
              className="mb-4 w-full grow resize-none rounded-[4px] border-[1px] border-[#CECABA] bg-transparent py-2 px-3 text-[14px] text-dark-blue outline-0 placeholder:text-[#CFCAB8] focus:outline-none"
              rows={5}
              onChange={(e) => {
                setMessage(e.currentTarget.value);
              }}
              defaultValue={message}
              placeholder={"Beskriv vad som hände"}
              onKeyDown={(e) => {
                setMessage(e.currentTarget.value);
              }}
            />
            <Button
              onClick={onSubmitReport}
              title="Skicka felrapport"
              rightArrow={false}
              size="small"
            />
          </div>
        </Modal>
      )}
    </>
  );
}
