import React, { PropsWithChildren } from "react";

const closeIcon = new URL(
  "../assets/images/icon-close-dark.svg",
  import.meta.url
);

type Props = PropsWithChildren<{
  title: string;
  onClose: () => void;
  footerComp?: React.ReactElement;
}>;

const ModalPopup = ({ title = "", children, onClose, footerComp }: Props) => {
  return (
    <div
      className="fixed z-50 flex h-full w-full items-start justify-center"
      style={{ backgroundColor: "rgba(0, 0, 0, .75)" }}
    >
      <div className="flex max-h-[90vh] w-full justify-center pt-[50px]">
        <div className="relative flex w-auto max-w-[90%] flex-col rounded bg-theme-white p-8 pt-11 drop-shadow-xl">
          <div className="absolute right-0 top-0">
            <img
              src={closeIcon.href}
              alt="close"
              width="18"
              height="18"
              className="box-content h-fit max-w-none cursor-pointer p-4"
              onClick={onClose}
            />
          </div>
          {title !== "" && (
            <h1 className="mb-4 px-8 text-center font-intermedium text-[32px] text-dark-blue">
              {title}
            </h1>
          )}
          <div className="overflow-y-auto overflow-x-clip px-5">{children}</div>
          {footerComp}
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;
