import React, { ChangeEventHandler } from "react";

import ErrorFormMessage from "../ErrorFormMessage";
import QuestionTitle from "../flow/QuestionTitle";

type PropsType = {
  name: string;
  label?: string;
  type?: string;
  id?: string;
  placeholder?: string;
  customClasses?: string;
  labelClasses?: string;
  value?: string;
  required?: boolean;
  readonly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  info?: string;
};

export default function Input({
  type = "text",
  id,
  name,
  label,
  placeholder,
  required = false,
  readonly = false,
  onChange,
  customClasses = "",
  labelClasses = "",
  value,
  error,
  info,
}: PropsType) {
  if (error) {
    customClasses += " border-red-500";
    customClasses += " text-red-500";
    labelClasses += " text-red-500";
  }

  return (
    <>
      <QuestionTitle
        label={label}
        name={name}
        cssClasses={labelClasses}
        info={info}
      />
      <input
        type={type}
        id={id}
        name={name}
        onChange={onChange}
        className={`w-full rounded-[4px] border-[1px] border-[#CECABA] bg-theme-white p-[12px] text-[13px] leading-[18px] text-dark-blue placeholder:italic placeholder:text-[#736F62] read-only:bg-[#f6f6f6] focus:border-dark-blue focus:outline-none lg:p-[12px] ${customClasses}`}
        placeholder={placeholder}
        value={value !== null ? value : ""}
        required={required}
        readOnly={readonly}
      />
      {error ? <ErrorFormMessage message={error} /> : null}
    </>
  );
}
