import {
  assetsTypes,
  flowData,
  flowSets,
  PARTNER_TYPES,
} from "../../services/flow";
import { setUserData } from "../../services/ida";
import { dataDeepCopy, hashString } from "../../services/utils";
import {
  FlowAnswer,
  FlowAsset,
  FlowItem,
  FlowItemDependency,
  FlowOrganization,
  FlowPerson,
  FlowSet,
  FlowType,
} from "../../types/flow";

export const checkIfDependenciesMet = (
  el: { dependencies?: FlowItemDependency[] },
  flow: FlowType,
  currentSetData?: FlowSet
) => {
  const setData = currentSetData
    ? currentSetData
    : flowSets.find(
        (set) => set.id === flow.set && set.section === flow.section
      );
  if (el.dependencies) {
    const metDependencies = [];
    el.dependencies.map((dep) => {
      if (Array.isArray(dep)) {
        const metArrayDeps = [];
        dep.map((d) => {
          if (checkSingleDependency(d, flow, setData)) {
            metArrayDeps.push(dep);
          }
        });
        if (metArrayDeps.length > 0 && metArrayDeps.length !== dep.length) {
          metDependencies.push(dep);
        }
      } else {
        if (checkSingleDependency(dep, flow, setData)) {
          metDependencies.push(dep);
        }
      }
    });

    if (metDependencies.length !== el.dependencies.length) {
      return false;
    }
  }
  return true;
};

export const checkSingleDependency = (dep, flow, setData) => {
  if (dep.type === "value") {
    const metDependencies = [];
    const values = [flow.values];
    if (setData?.multiple) {
      const setVals = flow.values.find((el) => el.name === setData.multiple);
      if (setVals && setVals.value[flow.setIndex]) {
        values.push(setVals.value[flow.setIndex]);
      }
    }
    values.map((value) => {
      if (typeof value === "string") return;
      const res = value.find((el) => {
        if (el.name === dep.name) {
          const metValues = [];
          const depValues = dep.values;
          const depOperator = dep.op;

          depValues.map((depValue) => {
            if (el.value.includes(depValue)) {
              metValues.push(depValue);
            }
          });

          if (depOperator === "and" && depValues.length === metValues.length) {
            return true;
          }

          if (depOperator === "or" && metValues.length > 0) {
            return true;
          }

          if (depOperator === "notAnd" && metValues.length === 0) {
            return true;
          }

          return false;
        }
      });
      if (res) {
        metDependencies.push(dep);
      }
    });

    if (metDependencies.length > 0) {
      return true;
    }
    return false;
  }
  if (dep.type === "value-count") {
    const values = [...flow.values];
    const valToCheck = values.find((v) => v.name === dep.name);
    if (!valToCheck) {
      return false;
    }

    let v = valToCheck.value;
    const valOtherToCheck = values.find((v) => v.name === `${dep.name}-other`);
    if (valOtherToCheck && typeof valOtherToCheck === "object") {
      v = [...valToCheck.value, ...Object.values(valOtherToCheck.value)];
    }

    v = v.reduce((acc, ind) => {
      if (ind === "other") {
        return acc;
      }
      return [...acc, ind];
    }, []);

    if (dep.op === "=") {
      if (v.length === dep.values) {
        return true;
      }
    }

    if (dep.op === ">=") {
      if (v.length >= dep.values) {
        return true;
      }
    }

    return false;
  }
  if (dep.type === "asset-person") {
    const assetType = dep.assetType;
    const fieldIn = dep.in;

    const personIDs = dep.person.reduce((acc, p) => {
      const personFieldData = flow.values.find((f) => f.name === p);
      if (!personFieldData) {
        return acc;
      }
      return [...acc, ...personFieldData.value];
    }, []);

    if (!personIDs || !personIDs.length) {
      return false;
    }

    const [v, p, assets] = getDataFromAssetsSection(flow.values, []);
    const propery = assets.filter((a) => a.type === assetType);
    if (!propery || !propery.length) {
      return false;
    }

    let ownedPersons = [];
    propery.map((p) => {
      const fieldData = p.data.find((a) => a.name === fieldIn);
      const idsInData = personIDs.reduce((acc, id) => {
        if (fieldData && fieldData.value.includes(id)) {
          return [...acc, id];
        }
        return acc;
      }, []);
      if (idsInData.length > 0) {
        ownedPersons = [...ownedPersons, ...idsInData];
      }
    });

    const ownedPersonsUnique = [...new Set(ownedPersons)];
    return ownedPersonsUnique.length === personIDs.length;
  }
};

export const setValues = (
  state: FlowType,
  payload: { name: string; value: string | string[]; multiple?: string }
) => {
  const setData = flowSets.find(
    (set) => set.id === state.set && set.section === state.section
  );
  let nextPositions = state.nextPositions
    ? dataDeepCopy(state.nextPositions)
    : [];
  let name = payload.name;
  let values = [...state.values];

  if (state.status === "edit" && payload.name !== "assets-types") {
    if (payload.name === "preplan-funeral") {
      const hasPreplanAnswers =
        values.filter((v) => v.section === 5).length > 0;
      const showPreplan = payload.value.toString() === "yes";
      if (!showPreplan && hasPreplanAnswers) {
        values = values.reduce((acc, vl) => {
          if (vl.section === 5) {
            return acc;
          }
          return [...acc, vl];
        }, [] as FlowAnswer[]);
      }
    }
    if (payload.name === "write-lpa") {
      const hasLPAAnswers = values.filter((v) => v.section === 4).length > 0;
      const showLPA = payload.value.toString() === "yes";
      const isInNextPos = nextPositions.find((np) => np.section === 4);

      if (
        showLPA &&
        !hasLPAAnswers &&
        state.prevPosition &&
        state.prevPosition.section === 5 &&
        !isInNextPos
      ) {
        nextPositions.push({
          section: 4,
          set: 1,
          setIndex: 0,
          status: "started",
          validate: false,
        });
      }
      if (!showLPA && hasLPAAnswers) {
        values = values.reduce((acc, vl) => {
          if (vl.section === 4) {
            return acc;
          }
          return [...acc, vl];
        }, [] as FlowAnswer[]);
      }
      if (!showLPA && isInNextPos) {
        nextPositions = nextPositions.reduce((acc, np) => {
          if (np.section !== 4) {
            return [...acc, np];
          }
          return acc;
        }, []);
      }
    }

    const personSelects = flowData.reduce((qa, q) => {
      const it = q.items.reduce((ia, i) => {
        const fl = i.fields.reduce((fa, f) => {
          if (f.type === "persons-select") {
            return [...fa, f.name];
          }
          return fa;
        }, []);
        if (fl.length > 0) {
          return [...ia, ...fl];
        }
        return ia;
      }, []);

      if (it.length > 0) {
        return [...qa, ...it];
      }
      return qa;
    }, [] as string[]);
    const personSelectsUnique = [...new Set(personSelects)];

    const asTypes = assetsTypes.reduce(
      (acc, t) => [...acc, t.type],
      [] as string[]
    );

    const addNextPosition = (q, index = 0) => {
      const ifNextPosExist = nextPositions.find(
        (np) =>
          np.section === q.section && np.set === q.set && np.setIndex === index
      );
      const ifNextInAnswers = state.values.filter(
        (v) => v.section === q.section && v.set === q.set
      );
      const isInPrevPosition = state.prevPosition
        ? state.prevPosition.section === q.section &&
          state.prevPosition.set === q.set &&
          state.prevPosition.setIndex === index &&
          state.prevPosition.status === "started"
        : false;
      if (
        !ifNextPosExist &&
        ifNextInAnswers.length >= 1 &&
        state.section !== q.section &&
        !state.set !== q.set &&
        !isInPrevPosition
      ) {
        nextPositions.push({
          section: q.section,
          set: q.set,
          setIndex: index,
          status: "edit",
        });
      }
    };

    const affectedQuestions = flowData.filter((fd) => {
      if (!fd.dependencies) {
        return false;
      }
      return fd.dependencies.filter((f) => f.name === payload.name).length > 0;
    });

    const qFields = getFlatNamesList(affectedQuestions);
    const namesToSearch = [
      "marital-status",
      "partner-first-name",
      "partner-last-name",
      "children",
      "children-desc",
      "first-name",
      "last-name",
      "person-first-name",
      "person-last-name",
    ].includes(payload.name)
      ? [...qFields, ...personSelectsUnique]
      : [...qFields];

    values = values.reduce((acc, vl) => {
      if (namesToSearch.includes(vl.name)) {
        addNextPosition(vl);
        return acc;
      }

      if (asTypes.includes(vl.name)) {
        Object.entries(vl.value).map((item) => {
          let [index, qts] = item;

          qts = qts.reduce((acc, q) => {
            if (namesToSearch.includes(q.name)) {
              addNextPosition(q, parseInt(index));
              return acc;
            }
            return [...acc, q];
          }, []);
          vl.value[index] = qts;
        });
      }
      return [...acc, vl];
    }, [] as FlowAnswer[]);
  }

  if (setData?.multiple) {
    name = setData.multiple;
  }

  const prevValues = values.filter((el) => {
    return el.name !== name;
  });
  if (payload.value.length === 0) {
    return [[...prevValues], nextPositions];
  }

  if (setData?.multiple) {
    const setVals = values.find((el) => {
      return el.name === name;
    });
    if (!setVals) {
      return [
        [
          ...values,
          {
            id: `${setData.section}.${setData.id}`,
            name: setData.multiple,
            value: { [state.setIndex]: [payload] },
            section: state.section,
            set: state.set,
          },
        ],
        nextPositions,
      ];
    } else {
      const ind = values.findIndex((el) => el.name === name);

      if (!values[ind].value[state.setIndex]) {
        values[ind].value[state.setIndex] = [];
      }
      const currentPool = values[ind].value[state.setIndex];

      const currentValueIndex = currentPool.findIndex((el) => {
        if (payload.multiple) {
          return el.name === payload.multiple;
        }
        return el.name === payload.name;
      });
      if (payload.multiple) {
        if (currentValueIndex >= 0) {
          if (
            values[ind].value[state.setIndex][currentValueIndex].value[
              payload.index
            ]
          ) {
            const vs =
              values[ind].value[state.setIndex][currentValueIndex].value[
                payload.index
              ];
            if (typeof vs === "string") {
              return [[...values], nextPositions];
            }
            const curData = vs.filter((el) => el.name !== payload.name);
            values[ind].value[state.setIndex][currentValueIndex].value[
              payload.index
            ] = [...curData, { name: payload.name, value: payload.value }];
          } else {
            values[ind].value[state.setIndex][currentValueIndex].value[
              payload.index
            ] = [{ name: payload.name, value: payload.value }];
          }
        } else {
          values[ind].value[state.setIndex].push({
            id: payload.id,
            name: payload.multiple,
            value: {
              [payload.index]: [{ name: payload.name, value: payload.value }],
            },
            section: state.section,
            set: state.set,
          });
        }
      } else {
        if (currentValueIndex >= 0) {
          values[ind].value[state.setIndex][currentValueIndex] = payload;
        } else {
          values[ind].value[state.setIndex].push(payload);
        }
      }
    }
    return [[...values], nextPositions];
  }

  if (payload.multiple) {
    const currentValueIndex = values.findIndex((el) => {
      return el.name === payload.multiple;
    });

    if (currentValueIndex >= 0) {
      if (values[currentValueIndex].value[payload.index]) {
        const vs = values[currentValueIndex].value[payload.index];
        if (typeof vs === "string") {
          // return { ...state };
          return [[...values], nextPositions];
        }
        const curData = vs.filter((el) => el.name !== payload.name);
        values[currentValueIndex].value[payload.index] = [
          ...curData,
          { name: payload.name, value: payload.value },
        ];
      } else {
        values[currentValueIndex].value[payload.index] = [
          { name: payload.name, value: payload.value },
        ];
      }

      return [[...values], nextPositions];
    }

    return [
      [
        ...prevValues,
        {
          id: payload.id,
          name: payload.multiple,
          value: {
            [payload.index]: [{ name: payload.name, value: payload.value }],
          },
          section: state.section,
          set: state.set,
        },
      ],
      nextPositions,
    ];
  }

  return [[...prevValues, payload], nextPositions];
};

export const getNextSet = (state: FlowType, nextSet: number) => {
  const ifSetExists = flowSets.find(
    (fd) => fd.section === state.section && fd.id === nextSet
  );
  if (!ifSetExists) {
    return false;
  }

  const setData = flowSets.find(
    (set) => set.id === nextSet && set.section === state.section
  );

  if (!checkIfDependenciesMet(setData, state, setData)) {
    return getNextSet(state, nextSet + 1);
  }

  return nextSet;
};

export const pushUserDataToStorage = (
  accessToken: string | undefined,
  flow: FlowType
) => {
  if (!accessToken) {
    return;
  }

  void setUserData(accessToken, [
    {
      key: "flow",
      value: JSON.stringify(flow),
    },
  ]);
};

export const createPersonOrOrg = (data) => {
  let type = data.find((h) => h.name === "type");
  if (!type || !type.value) {
    type = { value: ["person"] };
  }

  type = type.value.toString();

  if (type === "person") {
    const fn = data.find((el) => el.name === "person-first-name");
    const ln = data.find((el) => el.name === "person-last-name");
    const bt = data.find((el) => el.name === "person-birthday");
    const rl = data.find((el) => el.name === "person-relation");
    const firstName = fn ? fn.value.toString() : "";
    const lastName = ln ? ln.value.toString() : "";
    const person: FlowPerson = {
      id: hashString(`person_${firstName}_${lastName}`),
      firstName: firstName,
      lastName: lastName,
      birthday: bt ? bt.value.toString() : "",
      personalNumber: bt ? bt.value.toString() : "",
      relation: rl ? rl.value.toString() : "",
      type: "person",
    };
    return person;
  }
  if (type === "org") {
    const on = data.find((el) => el.name === "org-name");
    const onum = data.find((el) => el.name === "org-number");
    const name = on ? on.value.toString() : "";
    const number = onum ? onum.value.toString() : "";
    const org: FlowOrganization = {
      id: hashString(`org_${name}_${number}`),
      name: name,
      number: number,
      type: "org",
    };
    return org;
  }
};

export const getPersonsFromGeneralSection = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
): [FlowAnswer[], FlowPerson[]] => {
  let persons = dataDeepCopy(p);
  const values = dataDeepCopy(v);

  const msObject = values.find((el) => el.name === "marital-status");
  if (msObject && PARTNER_TYPES.includes(msObject.value.toString())) {
    const fn = values.find((el) => el.name === "partner-first-name");
    const ln = values.find((el) => el.name === "partner-last-name");
    const bt = values.find((el) => el.name === "partner-birthday");
    const firstName = fn ? fn.value.toString() : "";
    const lastName = ln ? ln.value.toString() : "";

    const person: FlowPerson = {
      id: hashString(`person_${firstName}_${lastName}`),
      firstName: firstName,
      lastName: lastName,
      birthday: bt ? bt.value.toString() : "",
      personalNumber: bt ? bt.value.toString() : "",
      relation: msObject.value.toString(),
      type: "person",
    };

    persons.push(person);
  }

  const childrenData = values.filter(
    (el) => el.name === "children-current" || el.name === "children-before"
  );
  const children = childrenData.reduce((acc, childCurrent) => {
    const dt = Object.entries(childCurrent.value).reduce((acc, current) => {
      const [key, value] = current;
      if (typeof value !== "object") return acc;
      const fn = value.find((el) => el.name === "first-name");
      const ln = value.find((el) => el.name === "last-name");
      const bt = value.find((el) => el.name === "birthday");
      const firstName = fn ? fn.value.toString() : "";
      const lastName = ln ? ln.value.toString() : "";

      const child: FlowPerson = {
        id: hashString(`person_${firstName}_${lastName}`),
        firstName: firstName,
        lastName: lastName,
        birthday: bt ? bt.value.toString() : "",
        personalNumber: bt ? bt.value.toString() : "",
        relation: "child",
        type: "person",
      };

      return [...acc, child];
    }, []);
    if (dt.length > 0) {
      const valueIndex = values.findIndex(
        (el) => el.name === childCurrent.name
      );
      if (valueIndex > -1) {
        values[valueIndex].value = dt.reduce((acc, el) => [...acc, el.id], []);
      }
    }
    return [...acc, ...dt];
  }, [] as FlowPerson[]);
  if (children.length > 0) {
    persons = [...persons, ...children];
  }

  const relativesData = values.filter(
    (el) => el.name === "other-relatives-list"
  );
  const relatives = relativesData.reduce((acc, relCurrent) => {
    const dt = Object.entries(relCurrent.value).reduce((acc, current) => {
      const [key, value] = current;
      if (typeof value !== "object") return acc;
      const fn = value.find((el) => el.name === "first-name");
      const ln = value.find((el) => el.name === "last-name");
      const bt = value.find((el) => el.name === "birthday");
      const rl = value.find((el) => el.name === "relation");
      const firstName = fn ? fn.value.toString() : "";
      const lastName = ln ? ln.value.toString() : "";

      const relative: FlowPerson = {
        id: hashString(`person_${firstName}_${lastName}`),
        firstName: firstName,
        lastName: lastName,
        birthday: bt ? bt.value.toString() : "",
        personalNumber: bt ? bt.value.toString() : "",
        relation: rl ? rl.value.toString() : "",
        type: "person",
      };

      return [...acc, relative];
    }, []);
    if (dt.length > 0) {
      const valueIndex = values.findIndex((el) => el.name === relCurrent.name);
      if (valueIndex > -1) {
        values[valueIndex].value = dt.reduce((acc, el) => [...acc, el.id], []);
      }
    }
    return [...acc, ...dt];
  }, [] as FlowPerson[]);
  if (relatives.length > 0) {
    persons = [...persons, ...relatives];
  }

  return [values, persons];
};

export const getPersonsFromObjectivesSection = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
) => {
  const [values, people] = savePeopleFromDataKeys(
    [
      "heirs",
      "inheritance-partner-death-persons",
      "inheritance-partner-death-before",
      "partner-share-inheritance-persons",
    ],
    v,
    p
  );

  return [values, people];
};

export const getPersonsForInheritanceDistribution = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
): [FlowAnswer[], FlowPerson[]] => {
  const [values, people] = savePeopleFromDataKeys(
    ["heirs", "partner-share-inheritance-persons"],
    v,
    p
  );

  return [values, people];
};

export const getDataFromAssetsSection = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
) => {
  const assets = [];
  const persons = dataDeepCopy(p);
  const values = dataDeepCopy(v);
  const types = assetsTypes.reduce((acc, type) => [...acc, type.type], []);

  types.map((type) => {
    const typeValues = values.find((v) => v.name === type);
    const typeValuesKey = values.findIndex((v) => v.name === type);
    if (!typeValues || !typeValues.value) return;

    const ids = [];
    Object.values(typeValues.value).map((ts) => {
      if (typeof ts === "string") {
        return;
      }
      let as = [...ts];
      ["asset-owned-persons", "asset-bequeath-persons"].map((key) => {
        const otherPersonIds = [];
        const otherPerson = as.find((a) => a && a.name === `${key}-other`);
        if (otherPerson) {
          Object.values(otherPerson.value).map((v) => {
            const p = createPersonOrOrg(v);
            if (p) {
              persons.push(p);
              otherPersonIds.push(p.id);
            }
          });

          as = as.reduce((acc, a) => {
            if (a && a.name === `${key}-other`) {
              return [...acc];
            }
            return [...acc, a];
          }, []);

          // const otherPersonIndex = as.findIndex(
          //   (a) => a && a.name === `${key}-other`
          // );
          // if (otherPersonIndex >= 0) {
          //   delete as[otherPersonIndex];
          // }

          const personIndex = as.findIndex((a) => a && a.name === key);
          if (personIndex >= 0) {
            as[personIndex].value = as[personIndex].value.filter(
              (v) => v !== "other"
            );
          }
          as[personIndex].value = [...as[personIndex].value, ...otherPersonIds];
        }
      });

      const asset: FlowAsset = {
        id: hashString(JSON.stringify(as)),
        type: type,
        data: as,
      };
      ids.push(asset.id);
      assets.push(asset);
    });

    values[typeValuesKey].value = [...ids];
  });

  return [values, persons, assets];
};

export const getDataFromLPASection = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
) => {
  let people = dataDeepCopy(p);
  let values = dataDeepCopy(v);

  [values, people] = savePeopleFromDataKeys(
    ["lpa-principal-persons"],
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-principal-person-second-hand",
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-principal-person-third-hand",
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-property-conflict-person-a",
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-property-conflict-person-b",
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-property-conflict-person-c",
    values,
    people
  );

  [values, people] = saveSinglePersonFromDataKeys(
    "lpa-audit-persons",
    values,
    people
  );

  return [values, people];
};

export const getDataFromPrePlanSection = (
  v: FlowAnswer[],
  p = [] as FlowPerson[]
) => {
  let people = dataDeepCopy(p);
  let values = dataDeepCopy(v);

  [values, people] = savePeopleFromDataKeys(["pln-fp-access"], values, people);

  const cpObject = values.find((el) => el.name === "pln-contact-person");
  const cpObjectIndex = values.findIndex(
    (el) => el.name === "pln-contact-person"
  );

  if (!cpObject) {
    return [values, people];
  }

  if (cpObject.value.includes("other")) {
    const fn = values.find((el) => el.name === "pln-contact-person-first-name");
    const ln = values.find((el) => el.name === "pln-contact-person-last-name");
    const bt = values.find((el) => el.name === "pln-contact-person-birthday");
    const rl = values.find((el) => el.name === "pln-contact-person-relation");
    const ph = values.find((el) => el.name === "pln-contact-person-phone");
    const firstName = fn ? fn.value.toString() : "";
    const lastName = ln ? ln.value.toString() : "";
    const person: FlowPerson = {
      id: hashString(`person_${firstName}_${lastName}`),
      firstName: firstName,
      lastName: lastName,
      birthday: bt ? bt.value.toString() : "",
      personalNumber: bt ? bt.value.toString() : "",
      relation: rl ? rl.value.toString() : "",
      phoneNumber: ph ? ph.value.toString() : "",
      type: "person",
    };
    people = [...people, person];

    values[cpObjectIndex].value = [person.id];
    values = values.filter(
      (v) =>
        ![
          "pln-contact-person-first-name",
          "pln-contact-person-last-name",
          "pln-contact-person-birthday",
          "pln-contact-person-relation",
          "pln-contact-person-phone",
        ].includes(v.name)
    );
  } else {
    const personId = cpObject.value.toString();
    const personIndex = people.findIndex((p) => p.id === personId);
    const ph = values.find(
      (el) => el.name === "pln-contact-person-phone-number"
    );
    if (personIndex > -1 && ph) {
      people[personIndex] = {
        ...people[personIndex],
        phoneNumber: ph ? ph.value.toString() : "",
      };

      values = values.filter(
        (v) => v.name !== "pln-contact-person-phone-number"
      );
    }
  }

  return [values, people];
};

export const savePeopleFromDataKeys = (
  keys: string[],
  v: FlowAnswer[],
  p: FlowPerson[]
): [FlowAnswer[], FlowPerson[]] => {
  let values = dataDeepCopy(v);
  let people = dataDeepCopy(p);
  keys.map((key) => {
    const elName = `${key}-other`;
    const hoObject: {
      id: string;
      name: string;
      value: { [d: string]: { name: string; value: string[] }[] };
    } = values.find((el) => el.name === elName);

    if (!hoObject || !hoObject.value) {
      return false;
    }

    const peps: (FlowPerson | FlowOrganization)[] = [];
    Object.values(hoObject.value).map((heir) => {
      if (typeof heir === "string") {
        return;
      }

      const po = createPersonOrOrg(heir);
      if (po) {
        peps.push(po);
      }
    });

    if (peps.length === 0) {
      return false;
    }

    const hValueIndex = values.findIndex((el) => el.name === key);
    if (hValueIndex > -1) {
      values[hValueIndex].value = values[hValueIndex].value.filter(
        (v) => v !== "other"
      );
      values[hValueIndex].value = [
        ...values[hValueIndex].value,
        ...peps.reduce((acc, h) => [...acc, h.id], [] as string[]),
      ];
    }

    values = values.filter((el) => el.name !== elName);
    people = [...people, ...peps];
  });

  return [values, people];
};

export const saveSinglePersonFromDataKeys = (
  key: string,
  values,
  people
): [FlowAnswer[], FlowPerson[]] => {
  const objectIndex = values.findIndex((el) => el.name === key);
  if (objectIndex > -1) {
    const object = values[objectIndex];
    if (object && object.value.includes("other")) {
      const fn = values.find((el) => el.name === `${key}-first-name`);
      const ln = values.find((el) => el.name === `${key}-last-name`);
      const bt = values.find((el) => el.name === `${key}-birthday`);
      const rl = values.find((el) => el.name === `${key}-relation`);
      const firstName = fn ? fn.value.toString() : "";
      const lastName = ln ? ln.value.toString() : "";
      const person: FlowPerson = {
        id: hashString(`person_${firstName}_${lastName}`),
        firstName: firstName,
        lastName: lastName,
        birthday: bt ? bt.value.toString() : "",
        personalNumber: bt ? bt.value.toString() : "",
        relation: rl ? rl.value.toString() : "",
        type: "person",
      };
      people = [...people, person];
      values[objectIndex].value = [person.id];
      values = values.filter(
        (v) =>
          ![
            `${key}-first-name`,
            `${key}-last-name`,
            `${key}-birthday`,
            `${key}-relation`,
          ].includes(v.name)
      );
    }
  }

  return [values, people];
};

export const savePartnerAsHeir = (v: FlowAnswer[], p = [] as FlowPerson[]) => {
  const people = dataDeepCopy(p);
  const values = dataDeepCopy(v);

  const ppObject = values.find((el) => el.name === "protect-partner");
  if (!ppObject || !ppObject.value || !ppObject.value.includes("yes")) {
    return [values, people];
  }

  const partnerObject = people.find((p) => PARTNER_TYPES.includes(p.relation));
  if (!partnerObject) {
    return [values, people];
  }

  return [
    [...values, { id: "2.1.3", name: "heirs", value: [partnerObject.id] }],
    people,
  ];
};

export const getFlatNamesList = (questions: FlowItem[]) => {
  return questions.reduce((qa, q) => {
    if (q.multiple) {
      return [...qa, q.multiple];
    }
    const it = q.items.reduce((ia, i) => {
      const fl = i.fields.reduce((fa, f) => {
        return [...fa, f.name];
      }, []);
      return [...ia, ...fl];
    }, []);
    return [...qa, ...it];
  }, [] as string[]);
};
