import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";

type PropsType = {
  file: string | ArrayBuffer;
  width?: number | string;
  displayPages?: number;
  theme?: string;
};

export default function PdfPreview({
  file,
  width,
  displayPages,
  theme,
}: PropsType) {
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    if (displayPages) {
      setNumPages(displayPages);
    } else {
      setNumPages(numPages);
    }
  }

  let styles = "bg-green-50 drop-shadow-xl";
  if (theme === "border") {
    styles = "border-[1px] border-theme-neutral4 rounded-[6px] overflow-hidden";
  }

  return (
    <div className="relative">
      {file && (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading="">
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={width}
              className={`relative mb-3 lg:mb-8 ${styles}`}
              loading=""
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      )}
    </div>
  );
}
