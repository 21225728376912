import React, { useState } from "react";

import { useFlowContext } from "../context/FlowContext";
import { getCheckIcon, getCloseIcon, getRestartIcon } from "../services/icons";
import Button from "./Button";
import LoaderScreen from "./LoaderScreen";
import Modal from "./Modal";

type PropTypes = {
  title?: string;
  hardReset?: boolean;
};

export default function FlowRestart({ title, hardReset = false }: PropTypes) {
  const [loading, setLoading] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const { restartFlow } = useFlowContext();

  const onRestartFlow = () => {
    setLoading(true);
    void restartFlow(hardReset);
    setShowRestartModal(false);
  };

  const onRestartModalClose = () => {
    setShowRestartModal(false);
  };

  if (loading) {
    return <LoaderScreen />;
  }

  return (
    <>
      <div
        className="flex cursor-pointer items-center gap-2 lg:flex-row"
        onClick={() => setShowRestartModal(true)}
      >
        <div className="border-b-[1px] border-b-theme-primary1 pb-[2px] font-intermedium text-[14px] uppercase leading-none tracking-[0.12em] text-theme-primary1">
          {title ? title : "gör om din livsplanering"}
        </div>
        {getRestartIcon("#0C2632", "16", "100%")}
      </div>
      {showRestartModal && (
        <Modal onClose={onRestartModalClose} title="">
          <div className="bg-theme-white p-5 lg:p-0">
            <div className="mb-6 font-intermedium text-theme-primary1">
              Är du säker på att du vill börja om med din Livsplanering?
            </div>
            <div className="flex flex-col justify-between gap-5 lg:flex-row">
              <Button
                onClick={onRestartFlow}
                title="Ja, gör om"
                rightArrow={false}
                theme="white"
                icon={{ width: -20 }}
                iconComp={getCheckIcon("#0C2632", "22", "100%", "2")}
              />
              <Button
                onClick={onRestartModalClose}
                title="Nej, avbryt"
                rightArrow={false}
                icon={{ width: -20 }}
                iconComp={getCloseIcon("#FFFFFF", "16", "100%", "2")}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
