import React, { useEffect } from "react";

import LoaderScreen from "../components/LoaderScreen";
import config from "../config";

const websiteUrl: string = config.website_url;

export default function AffiliatePage() {
  useEffect(() => {
    const loc = window.location;
    window.location.href = `${websiteUrl}${loc.pathname}${loc.search}`;
    return;
  }, []);

  return <LoaderScreen />;
}
