import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

import config from "../config";
import {
  FlowItemField,
  FlowOrganization,
  FlowPerson,
  FlowType,
} from "../types/flow";
import { FAMILY_RELATION_OPTIONS, RELATION_OPTIONS } from "./flow";
import { hex_md5 } from "./md5.js";

export const generateRandomString = (length = 5): string => {
  return Math.random()
    .toString(36)
    .substr(2, length)
    .split("")
    .map((c) => (Math.random() < 0.5 ? c.toUpperCase() : c))
    .join("");
};

export const getPhoneNumber = (): [string, string, () => void] => {
  const phone = "010-888 63 81";
  const phoneFull = phone
    .replaceAll("010", "+4610")
    .replaceAll(" ", "")
    .replaceAll("-", "");
  return [
    phone,
    phoneFull,
    () => {
      if (window.__ctm) {
        window.__ctm.main.runNow();
      }
    },
  ];
};

export function encodeBase64(data: object | string | number): string {
  const str = typeof data !== "string" ? JSON.stringify(data) : data;
  const bytes = new TextEncoder().encode(str);
  const binString = String.fromCodePoint(...bytes);
  return window.btoa(binString);
}

export function decodeBase64(data: string) {
  const binString = window.atob(data);
  const dt = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(dt);
}

export function dataDeepCopy<T>(data: T) {
  return JSON.parse(JSON.stringify(data)) as T;
}

export function isDesktopView() {
  return window.innerWidth >= 1024;
}

export function formatPrice(value: string | number): string {
  const v = typeof value === "string" ? parseInt(value, 10) : value;

  if (v === 0) {
    return v.toString();
  }
  const p = Math.round(v / 100).toString();

  const len = p.length;
  const div = Math.floor(len / 3);

  const parts = [];
  for (let i = 1; i <= div + 1; i++) {
    let start = len - i * 3;
    let l = 3;
    if (start < 0) {
      l = l + start;
      start = 0;
    }
    parts.push(p.substr(start, l));
  }

  return parts.reverse().join(" ").trim();
}

export const isFieldValid = (
  value: string | { [k: string]: number }[],
  type = "text"
): string | boolean => {
  const personNumberRegex = "(^\\d{12}$)|(^\\d{8}\\-\\d{4}$)";
  // const emailRegex = "^[a-z0-9._\\-\\+]{1,64}@[a-z0-9.]{1,64}$";
  const orgNumberRegex = "^(?:(\\d{10}){1}|(\\d{6}\\-\\d{4}){1})$";
  const emailRegex =
    "^[a-zA-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-z0-9](?:[a-zA-z0-9-]*[a-z0-9])?\\.)+[a-zA-z0-9](?:[a-zA-z0-9-]*[a-zA-z0-9])?$";
  const phoneNumberRegex = "^\\+{0,1}\\d{8,12}$";
  const personNumberBirthdayRegex = "^(\\d{8}|\\d{12})$";

  if (typeof value === "string") {
    switch (type) {
      case "range":
        if (value === "") {
          return "Det här fältet måste du fylla i för att kunna gå vidare";
        }
        break;
      case "checkbox": {
        if (!value || value.length === 0) {
          return "Du måste välja ett alternativ för att kunna gå vidare";
        }
        break;
      }
      case "radio":
        if (value === "") {
          return "Du måste välja ett alternativ för att kunna gå vidare";
        }
        break;
      case "personnummer":
        if (value.match(personNumberRegex) === null) {
          return "Fyll i med 12 siffror, ÅÅÅÅMMDDXXXX";
        }
        break;
      case "orgnummer":
        if (value.match(orgNumberRegex) === null) {
          return "Fyll i med 10 siffror, ÅÅMMDDXXXX";
        }
        break;
      case "email":
        if (value.match(emailRegex) === null) {
          return "Det verkar vara fel e-postadress. Försök igen";
        }
        break;
      case "postcode":
        if (value === "" || value.length !== 5) {
          return "Fyll i med 5 siffror, XXXXX";
        }
        break;
      case "phone":
        if (value.match(phoneNumberRegex) === null) {
          return "Är det rätt telefonnummer? Försök igen";
        }
        break;
      case "birthday-personnummer":
        if (value.match(personNumberBirthdayRegex) === null) {
          return "Fyll i med personnummer med/utan 4 sista siffrorna";
        }
        break;
      case "text":
      default:
        if (value === "") {
          return "Det här fältet måste du fylla i för att kunna gå vidare";
        } else if (value.length < 2) {
          return "Du måste fylla i minst två tecken i fältet";
        }
    }
  }
  if (typeof value === "object") {
    switch (type) {
      case "percentage": {
        const total = value.reduce((a, v) => {
          return a + parseInt(Object.values(v).toString());
        }, 0);
        if (total !== 100) {
          return "Du måste fördela 100% för att gå vidare";
        }
      }
    }
  }

  return true;
};

export const inputValueMask = (value: string, type = "numbers") => {
  if (type === "numbers") {
    return value.replace(/[^0-9]/g, "");
  }
};

export const stripEmojis = (str: string) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();

export const removeHtmlTags = (str: string) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, "");
};

export const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updatePosition);

    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export const getPersonFullLabel = (person: FlowPerson | FlowOrganization) => {
  if (person.type === "person") {
    const relLabel = [...RELATION_OPTIONS, ...FAMILY_RELATION_OPTIONS].find(
      (o) => o.value === person.relation
    )?.label;
    const parts = [`${person.firstName} ${person.lastName}`];
    if (relLabel) {
      parts.push(relLabel?.toLowerCase());
    }
    return parts.join(", ");
  }
  if (person.type === "org") {
    return `${person.name}, ${person.number}`;
  }
  return "";
};

export const processQuestionLabel = (lbl: FlowItemField["label"]) => {
  if (!lbl) {
    return "";
  }
  if (typeof lbl === "string") {
    return lbl;
  }
  if (Array.isArray(lbl)) {
    return lbl.toString();
  }
  return lbl;
};

export const joinArrayWithAnd = (arr: string[]) =>
  arr.reduce((acc, str, ind) => {
    if (ind === 0) {
      return str;
    } else if (ind >= 1 && ind < arr.length - 1) {
      return `${acc}, ${str}`;
    } else {
      return `${acc} och ${str}`;
    }
  }, "");

export const formatDocumentDate = (date: string) => {
  const dt = new Date(date);
  if (!dt) {
    return "";
  }

  let month = (dt.getUTCMonth() + 1).toString();
  if (month.length === 1) {
    month = `0${month}`;
  }

  let day = dt.getUTCDate().toString();
  if (day.length === 1) {
    day = `0${day}`;
  }
  return `${dt.getUTCFullYear()}.${month}.${day}`;
};

export const getDomainForCookie = () => {
  const host = location.host;
  if (host.split(".").length === 1) {
    return null;
  }
  const domainParts = host.split(".");
  domainParts.shift();
  return "." + domainParts.join(".");
};

export const isLPAEnabled = (flow: FlowType) => {
  const lpa = flow.values.find((v) => v.name === "write-lpa");
  return lpa && lpa.value.toString() === "yes";
};

export const isPreplanEnabled = (flow: FlowType) => {
  const ppf = flow.values.find((v) => v.name === "preplan-funeral");
  return ppf && ppf.value.toString() === "yes";
};

export const hashString = (str: string) => {
  return hex_md5(str);
};

export const logErrors = (
  error: unknown,
  data?: { [p: string]: any } | null
) => {
  if (data) {
    Sentry.setContext("Logged Data", data);
  }
  Sentry.captureException(error);

  if (config.stage !== "production") {
    console.log(error);
  }
};
