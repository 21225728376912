import React, { useEffect, useState } from "react";

import {
  useFlowContext,
  useFlowDispatchContext,
} from "../../context/FlowContext";
import { flowSets } from "../../services/flow";
import { getButtonArrow } from "../../services/icons";
import { isDesktopView } from "../../services/utils";

export default function BackButton() {
  const [visible, setVisible] = useState(false);
  const { flow } = useFlowContext();
  const flowDispatch = useFlowDispatchContext();

  useEffect(() => {
    if (
      !isDesktopView() ||
      !["started", "set-edit"].includes(flow.status) ||
      flow.set <= 1 ||
      flow.prevPosition
    ) {
      setVisible(false);
      return;
    }

    const setOfSection = flowSets.filter((fs) => fs.section === flow.section);
    if (setOfSection.length < 2) {
      setVisible(false);
      return;
    }

    setVisible(true);
  }, [flow.status, flow.set, flow.prevPosition]);

  const onClickHandle = () => {
    flowDispatch({ type: "prev-history" });
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      className="absolute top-[-25px] left-0 flex cursor-pointer flex-row items-center gap-1 font-intermedium text-[12px] uppercase tracking-[2.4px] text-theme-primary1"
      onClick={onClickHandle}
    >
      <div className="rotate-180">
        {getButtonArrow("#0C2632", "14", "100%")}
      </div>
      <span className="border-b-[1px] border-theme-primary1 pb-[1px] leading-none">
        tillbaka
      </span>
    </div>
  );
}
