import React, { useState } from "react";

import { useFlowContext } from "../../context/FlowContext";
import { getInfoIcon } from "../../services/icons";
import { FlowItemFieldInfo } from "../../types/flow";
import Modal from "../Modal";

type PropTypes = {
  label?: string;
  name: string;
  cssClasses?: string;
  info?: FlowItemFieldInfo;
};

export default function QuestionTitle({
  label,
  cssClasses = "",
  info,
  name,
}: PropTypes) {
  const [modal, setModal] = useState(false);

  const { flow, replaceValuesInTitle } = useFlowContext();

  const renderLabel = () => {
    return replaceValuesInTitle(label);
  };

  const getButtonId = () => {
    if (!info) {
      return "";
    }
    let prefix = `chapter_${flow.section}`;
    if (flow.section === 100) {
      prefix = "finish_will";
    }
    return `${prefix}_infobutton_${info.id}`;
  };

  const renderInfoText = () => {
    if (!info) {
      return "";
    }

    if (typeof info.text === "string") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: info.text,
          }}
        />
      );
    }

    if (Array.isArray(info.text)) {
      return info.text.map((t) => {
        return (
          <div
            key={t.length}
            className="mb-5"
            dangerouslySetInnerHTML={{
              __html: t,
            }}
          />
        );
      });
    }
  };

  const renderSupportText = () => {
    if (flow.section === 4) {
      return (
        <div className="font-intermedium italic">
          <div className="mb-0">
            Är du osäker på vilket val du ska göra? Vi finns här för dig när du
            behöver hjälp. Använd vår supportfunktion (oftast svar inom 3 min)
            nere i det högra hörnet.
          </div>
        </div>
      );
    }

    return (
      <div className="font-intermedium">
        <div className="mb-0">
          Är du osäker på vilket val du ska göra? Vi finns här för dig när du
          behöver hjälp. Använd vår supportfunktion (oftast svar inom 3 min)
          nere i det högra hörnet. Eller maila oss direkt på{" "}
          <a
            className="text-theme-primary2"
            href="mailto:support@fenixfamily.se"
          >
            support@fenixfamily.se
          </a>
          .
        </div>
      </div>
    );
  };

  if (!label) {
    return null;
  }

  return (
    <label
      className={`mb-[10px] block font-intermedium text-dark-blue ${cssClasses}`}
      htmlFor={name}
    >
      {renderLabel()}
      {info && (
        <span
          className="relative top-[4px] ml-0.5 inline-block cursor-pointer lg:ml-1.5"
          onClick={() => setModal(true)}
          id={getButtonId()}
        >
          {getInfoIcon("#0C2632", "20", "100%")}
        </span>
      )}
      {modal && info && (
        <Modal title="" onClose={() => setModal(false)}>
          <div className="w-full max-w-[500px] bg-theme-white p-5 lg:p-0">
            <div className="mb-5 font-intermedium text-[18px]">
              {renderLabel()}
            </div>
            <div className="mb-5">{renderInfoText()}</div>
            {renderSupportText()}
          </div>
        </Modal>
      )}
    </label>
  );
}
