import React from "react";

import Accordion from "../components/Accordion";
import VaultHead from "../components/VaultHead";
import { RequireUserAuth } from "../context/UserContext";
import { faq } from "../services/datalist";
import { getAlignLeftIcon } from "../services/icons";

function FaqVaultPage() {
  return (
    <>
      <VaultHead
        title="Frågor och svar"
        icon={getAlignLeftIcon("#0C2632", "22px", "100%")}
      />

      <div className="grow">
        <div className="lea mt-0 mb-3 max-w-2xl font-interbold text-[28px] italic leading-[30px] text-dark-blue lg:mb-5 lg:text-[48px] lg:leading-[52px]">
          Här har vi samlat svar på vanliga funderingar kring livsplanering
        </div>
        <div className="mt-3 max-w-[985px] py-16">
          <Accordion items={faq} color={"bg-neutral-yellow"} open={0} />
        </div>
      </div>
    </>
  );
}

export default function FaqVaultPageWrapper() {
  return (
    <RequireUserAuth>
      <FaqVaultPage />
    </RequireUserAuth>
  );
}
