import React, { useEffect, useState } from "react";

import { useUserContext } from "../context/UserContext";
import { updateProfileInfo } from "../services/ida";
import { isFieldValid } from "../services/utils";
import { ErrorFields } from "../types/entities";
import { UserProfileType } from "../types/user";
import Button from "./Button";
import ErrorFormMessage from "./ErrorFormMessage";
import FlowRestart from "./FlowRestart";
import LoaderIcon from "./LoaderIcon";

const formGroup = (
  label,
  name,
  value,
  onChangeHandle,
  type = "text",
  disabled = false
) => {
  return (
    <div>
      <label
        className="mb-[10px] block font-intermedium text-dark-blue"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        type={type}
        value={value}
        name={name}
        className="w-full rounded-[4px] border-[1px] border-[#CECABA] bg-theme-white p-[12px] text-[13px] leading-[18px] text-dark-blue placeholder:italic placeholder:text-[#736F62] read-only:bg-[#f6f6f6] focus:border-dark-blue focus:outline-none lg:p-[12px]"
        onChange={onChangeHandle}
        disabled={disabled}
      />
    </div>
  );
};

export default function ProfileEditForm({ onSuccessHandler }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const { user } = useUserContext();

  useEffect(() => {
    if (!user.profile) {
      return;
    }
    setAddress(user.profile.address.address ?? "");
    setPostcode(user.profile.address.postalCode ?? "");
    setCity(user.profile.address.city ?? "");
    setPhone(user.profile.phoneNumber ?? "");
    setEmail(user.profile.email ?? "");
  }, [user.profile]);

  const formSubmit = () => {
    if (!user.accessToken || !user.profile) {
      return;
    }

    const errors = validateData();
    if (Object.entries(errors).length > 0) {
      return;
    }

    const data: UserProfileType = {
      ...user.profile,
      address: {
        ...user.profile?.address,
        address: address,
        postalCode: postcode,
        city: city,
      },
      phoneNumber: phone,
      email: email,
    };

    setLoading(true);
    setMessage(undefined);
    void updateProfileInfo(user.accessToken, data)
      .then(() => {
        setMessage("Sparat");
        void onSuccessHandler();
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const validateData = () => {
    const errors = {} as ErrorFields;

    errors["email"] = isFieldValid(email, "email");
    errors["phone"] = isFieldValid(phone, "phone");
    errors["postcode"] = isFieldValid(postcode, "postcode");

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const removeError = (key: string) => {
    const errors = { ...errorFields };
    delete errors[key];
    setErrorFields(errors);
  };

  const getButtonText = () => {
    return (
      <span>
        {loading ? (
          <div className="flex w-full justify-center">
            <LoaderIcon size="w-5 h-5" />
          </div>
        ) : (
          "Spara"
        )}
      </span>
    );
  };

  return (
    <form>
      <div className="mb-5 flex flex-col gap-3 lg:mb-10 lg:gap-6">
        <div className="flex flex-col gap-3 lg:flex-row lg:gap-6">
          <div className="grow">
            {formGroup("Adress", "address", address, (e) =>
              setAddress(e.target.value)
            )}
          </div>
          <div className="">
            {formGroup("Postnummer", "postcode", postcode, (e) => {
              setPostcode(e.target.value);
              removeError("postcode");
            })}
            {errorFields["postcode"] ? (
              <ErrorFormMessage message={errorFields["postcode"]} />
            ) : null}
          </div>
        </div>
        <div className="">
          {formGroup("Stad", "city", city, (e) => setCity(e.target.value))}
        </div>
        <div className="">
          {formGroup(
            "Telefonnummer",
            "phone",
            phone,
            (e) => {
              removeError("phone");
              setPhone(e.target.value);
            },
            "tel"
          )}
          {errorFields["phone"] ? (
            <ErrorFormMessage message={errorFields["phone"]} />
          ) : null}
        </div>
        <div className="">
          {formGroup(
            "Email",
            "email",
            email,
            (e) => {
              removeError("email");
              setEmail(e.target.value);
            },
            "email"
          )}
          {errorFields["email"] ? (
            <ErrorFormMessage message={errorFields["email"]} />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col-reverse items-start justify-between gap-4 lg:flex-row lg:items-center">
        <FlowRestart />
        <div className="flex flex-row justify-end">
          <div className="flex flex-row items-center gap-3">
            {message && (
              <div className="text-[14px] text-[#499d49]">{message}</div>
            )}
            <Button
              onClick={formSubmit}
              title={getButtonText()}
              rightArrow={!loading}
              // cssClasses="min-w-[200px]"s
            />
          </div>
        </div>
      </div>
    </form>
  );
}
