export default {
  stage: process.env.NODE_ENV,
  baseurl: process.env.BASEURL,
  ida_api_url: process.env.IDA_API_URL,
  flow_api_url: process.env.FLOW_API_URL,
  stripe_publishable_key: process.env.STRIPE_PUBLISHABLE_KEY,
  website_url: process.env.WEBSITE_URL,
  sentry_dsn: process.env.SENTRY_DSN,
  feature_financial_overview: process.env.FEATURE_FINANCIAL_OVERVIEW,
  feature_error_reporting: process.env.FEATURE_ERROR_REPORTING,
};
