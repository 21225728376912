import * as Sentry from "@sentry/react";
import React, { useEffect, useRef, useState } from "react";

import Button from "../components/Button";

const bankIdLogo = new URL(
  "../assets/images/bankid-white.svg",
  import.meta.url
);
import { useNavigate } from "react-router-dom";

import ErrorFormMessage from "../components/ErrorFormMessage";
import LoaderIcon from "../components/LoaderIcon";
import QRC from "../components/QRCode";
import config from "../config";
import { useUserContext, useUserDispatchContext } from "../context/UserContext";
import { apiUrl, getProfileInfo } from "../services/ida";
import {
  getFromStorage,
  homeRedirectKey,
  partnerKey,
  removeFromStorage,
  setInStorage,
} from "../services/storage";
import { isDesktopView, isFieldValid } from "../services/utils";

const websiteUrl: string = config.website_url;

export default function LoginPage() {
  const [status, setStatus] = useState("init");
  const [loading, setLoading] = useState(true);
  const [qrHash, setQrHash] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [pnMode, setPnMode] = useState(false);
  const [personalNumber, setPersonalNumber] = useState("");
  const [personalNumberValidation, setPersonalNumberValidation] = useState<
    string | boolean
  >("");
  const [autoStartToken, setAutoStartToken] = useState();
  const qrInterval = useRef<NodeJS.Timer | null>(null);
  const collectInterval = useRef<NodeJS.Timer | null>(null);
  const { user } = useUserContext();
  const navigate = useNavigate();

  const userDispatch = useUserDispatchContext();

  useEffect(() => {
    if (user && user.info) {
      navigate("/vault");
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("pn")) {
      setPnMode(true);
      setLoading(false);
    } else {
      void startLogin();
    }

    const pk = searchParams.get(partnerKey);
    if (pk) {
      setInStorage(partnerKey, pk);
    } else {
      removeFromStorage(partnerKey);
    }

    const hrk = searchParams.get(homeRedirectKey);
    if (hrk) {
      setInStorage(homeRedirectKey, hrk);
    } else {
      removeFromStorage(homeRedirectKey);
    }

    return () => {
      clearFetchQrHash();
      setStatus("init");
    };
  }, [user.info]);

  useEffect(() => {
    if (!orderRef) {
      return;
    }

    if (!pnMode) {
      qrInterval.current = setInterval(() => {
        void fetchQrHash();
      }, 2000);
    }

    collectInterval.current = setInterval(() => {
      void fetchCollect();
    }, 2000);

    return () => {
      if (qrInterval.current) {
        clearInterval(qrInterval.current);
      }
      if (collectInterval.current) {
        clearInterval(collectInterval.current);
      }
    };
  }, [orderRef]);

  const startLogin = async () => {
    let paramString = "";

    if (pnMode) {
      const isValid = isFieldValid(personalNumber, "personnummer");
      if (isValid !== true) {
        setPersonalNumberValidation(isValid);
        return false;
      }

      const params = new URLSearchParams({
        personalNumber: personalNumber,
      });
      paramString = `?${params.toString()}`;

      setLoading(true);
    }
    setStatus("pending");

    const res = await fetch(`${apiUrl}/public/bankid/start${paramString}`, {
      method: "GET",
    }).catch((error) => {
      Sentry.captureException(error);
      console.error(error);

      clearFetchQrHash();
      setStatus("init");
      setLoading(false);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    if (!data.orderRef) {
      void startLogin();
      return;
    }
    setQrHash(data.qrHash);
    setOrderRef(data.orderRef);
    setAutoStartToken(data.autoStartToken);
    setLoading(false);
  };

  const fetchQrHash = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
    });
    const res = await fetch(
      `${apiUrl}/public/bankid/refreshQR?${params.toString()}`,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    ).catch((error) => {
      Sentry.captureException(error);
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    setQrHash(data.qrData);
  };

  const fetchCollect = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
    });
    const res = await fetch(
      `${apiUrl}/public/bankid/collect?${params.toString()}`,
      {
        method: "GET",
      }
    ).catch((error) => {
      Sentry.captureException(error);
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();

    const hintCode = data.hintCode;
    const collectStatus = data.status;
    const accessToken = data.accessToken;
    const refreshToken = data.refreshToken;
    const expiresIn = data.expiresIn;

    if (accessToken) {
      clearFetchQrHash();
      const profilePromise = getProfileInfo(accessToken)
        .then((data) => {
          if (data) {
            const payload = {
              profile: data,
              firstName: data.firstName,
              lastName: data.lastName,
              personNumber: data.personNumber,
              accessToken: accessToken,
              refreshToken: refreshToken,
              expDate: Date.now() + parseInt(expiresIn) * 1000,
            };
            userDispatch({
              type: "set-data",
              payload,
            });
            return payload;
          }
        })
        .catch((e) => console.log(e));

      void Promise.allSettled([profilePromise]).then(() => {
        setStatus("complete");
        navigate("/vault");
      });

      return;
    }

    switch (hintCode) {
      case "userCancel":
        setStatus("canceled");
        clearFetchQrHash();
        break;
      case "userSign":
        setStatus("signing");
        if (qrInterval.current) {
          clearInterval(qrInterval.current);
        }
        setQrHash("");
        break;
      case "startFailed":
        setStatus("failure");
        clearFetchQrHash();
        break;
      case "outstandingTransaction":
      default:
        break;
    }
  };

  const clearFetchQrHash = () => {
    if (qrInterval.current) {
      clearInterval(qrInterval.current);
    }
    if (collectInterval.current) {
      clearInterval(collectInterval.current);
    }
    setQrHash("");
  };

  const renderCancelButton = () => {
    return (
      <Button
        onClick={() => {
          if (pnMode) {
            clearFetchQrHash();
            setOrderRef("");
            setStatus("init");
            return;
          }
          setLoading(true);
          clearFetchQrHash();
          setStatus("init");
          const redirectUrl = getFromStorage(homeRedirectKey);
          window.location.href = redirectUrl
            ? websiteUrl + redirectUrl
            : websiteUrl + "/";
        }}
        title="Stäng"
        size="small"
        cssClasses="mt-4 mx-auto"
        rightArrow={false}
      />
    );
  };

  const renderStartButton = () => {
    return (
      <Button
        onClick={() => {
          setLoading(true);
          void startLogin();
        }}
        title="LOGGA IN MED MOBILT BANKID"
        cssClasses="mx-auto mt-5 lg:mt-10"
        icon={{ url: bankIdLogo, width: 30 }}
      />
    );
  };

  const renderOpenAppButton = (autoStartToken: string) => {
    if (isDesktopView()) {
      return (
        <a
          className="underline"
          href={`bankid:///?autostarttoken=${autoStartToken}&redirect=null`}
        >
          Använd BankID på denna enhet
        </a>
      );
    } else {
      return (
        <Button
          onClick={() => {
            window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
          }}
          title="Öppna BankID-appen"
          cssClasses="mx-auto mt-5 lg:mt-14"
          icon={{ url: bankIdLogo, width: 30 }}
        />
      );
    }
  };

  const renderText = () => {
    return (
      <div className="mx-auto mt-10 max-w-[400px] font-interlight text-[14px]">
        Genom att fortsätta accepterar jag{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Allmanna-villkor.pdf"
        >
          villkoren
        </a>{" "}
        för Fenix Familys tjänst samt bekräftar att jag har läst Familys{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Integritetspolicy.pdf"
        >
          integritetspolicy
        </a>
        .
      </div>
    );
  };

  if (loading) {
    return (
      <div className="mx-auto max-w-xl text-center">
        <LoaderIcon theme="dark" />
      </div>
    );
  }

  if (pnMode) {
    return (
      <div className="mx-auto max-w-lg text-center">
        {status === "init" && (
          <>
            <div className="mb-5 text-left lg:px-5">
              <div className="mb-4 text-center font-intermedium uppercase">
                Logga in med ditt BankID
              </div>
              <div>
                <label className="mb-2 block" htmlFor="personnummer">
                  Ditt personnummer
                </label>
                <input
                  className="block w-full min-w-[310px] max-w-full rounded-[8px] border-[1px] border-[#CFCAB8] bg-white p-2 ring-inset read-only:bg-[#f6f6f6] focus:outline-none focus-visible:ring-1 focus-visible:ring-[#cfcab86e] lg:p-3"
                  type="text"
                  name="personnummer"
                  id="personnummer"
                  inputMode="numeric"
                  placeholder="ÅÅÅÅMMDDXXXX"
                  value={personalNumber}
                  onChange={(event) => {
                    setPersonalNumberValidation("");
                    setPersonalNumber(event.currentTarget.value);
                  }}
                />
                {personalNumberValidation && (
                  <ErrorFormMessage message={personalNumberValidation} />
                )}
                <Button
                  onClick={() => {
                    void startLogin();
                  }}
                  title="LOGGA IN MED BANKID"
                  cssClasses="mx-auto mt-5"
                  icon={{ url: bankIdLogo, width: 30 }}
                />
              </div>
            </div>
          </>
        )}
        {status === "pending" && (
          <>
            <div className="mb-5 text-left lg:px-5">
              <div className="mb-2 text-center font-intermedium uppercase">
                Logga in med ditt BankID
              </div>
            </div>
            <div className="mx-auto h-auto w-full lg:max-w-[250px]">
              {autoStartToken && (
                <div className="mt-3">
                  {renderOpenAppButton(autoStartToken)}
                </div>
              )}
            </div>
            {isDesktopView() && renderCancelButton()}
            {renderText()}
          </>
        )}
        {status === "signing" && (
          <>
            <div className="mx-auto mb-5 max-w-[400px]">
              Skriv in din säkerhetskod i BankID- appen och välj Identifiera
              eller Skriv under.
            </div>
            {renderCancelButton()}
          </>
        )}
        {status === "canceled" && (
          <>
            <div className="mb-5">
              Identifieringen avbröts. <br /> Prova igen!
            </div>
            {renderStartButton()}
          </>
        )}
        {status === "failure" && (
          <>
            <div className="mx-auto mb-5 max-w-[400px]">
              Vi kunde inte hitta något BankID eller så misslyckades scanningen
              av QR-koden. Prova igen!
            </div>
            {renderStartButton()}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-lg text-center">
      {status === "init" && renderStartButton()}
      {status === "pending" && (
        <>
          <div className="mb-5 text-left lg:px-5">
            <div className="mb-2 text-center font-intermedium uppercase">
              Logga in med mobilt BankID
            </div>
            {isDesktopView() && (
              <ul className="list-outside list-decimal pl-5">
                <li>Öppna BankID-appen i mobilen.</li>
                <li>Tryck på QR-symbolen i BankID-appen.</li>
                <li>Rikta kameran mot QR-koden i denna ruta.</li>
              </ul>
            )}
          </div>
          <div className="mx-auto h-auto w-full lg:max-w-[250px]">
            {isDesktopView() && <QRC value={qrHash} />}
            {config.stage !== "production" && !isDesktopView() && (
              <QRC value={qrHash} />
            )}

            {autoStartToken && (
              <div className="mt-3">{renderOpenAppButton(autoStartToken)}</div>
            )}
          </div>
          {isDesktopView() && renderCancelButton()}
          {renderText()}
        </>
      )}
      {status === "signing" && (
        <>
          <div className="mx-auto mb-5 max-w-[400px]">
            Skriv in din säkerhetskod i BankID- appen och välj Identifiera eller
            Skriv under.
          </div>
          {renderCancelButton()}
        </>
      )}
      {status === "canceled" && (
        <>
          <div className="mb-5">
            Identifieringen avbröts. <br /> Prova igen!
          </div>
          {renderStartButton()}
        </>
      )}
      {status === "failure" && (
        <>
          <div className="mx-auto mb-5 max-w-[400px]">
            Vi kunde inte hitta något BankID eller så misslyckades scanningen av
            QR-koden. Prova igen!
          </div>
          {renderStartButton()}
        </>
      )}
    </div>
  );
}
