import * as Sentry from "@sentry/react";

import { useFlowContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";

export default function useLogError() {
  const { user } = useUserContext();
  const { flow } = useFlowContext();

  const provideScope = (scope: Sentry.Scope) => {
    // scope.clear();

    let packageData = {};
    if (user.package) {
      const { contracts, ...rest } = user.package;
      packageData = rest;
    }

    scope.setExtra("Flow", flow);
    scope.setContext("Context", {
      pkg: flow.pkg,
      package: packageData,
      userId: user.profile?.id,
    });
    return scope;
  };

  const logError = (error: Error | string) => {
    console.log("Error: ", error);
    return Sentry.captureException(error, provideScope);
  };

  const logMessage = (message: string) => {
    return Sentry.captureMessage(message, provideScope);
  };

  return { logError, logMessage };
}
