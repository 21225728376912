import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import {
  FLOW_CONTRACT_IDS,
  FLOW_DOC_IDS,
  FLOW_DOC_LPA_ID,
  FLOW_DOC_LPA_TYPE,
  FLOW_DOC_PP_ID,
  FLOW_DOC_PP_LETTER_ID,
  FLOW_DOC_WILL_ID,
  FLOW_DOC_WILL_TYPE,
} from "../services/flow";
import { getDocumentList } from "../services/ida";
import { formatDocumentDate } from "../services/utils";
import { IdaDocumentType } from "../types/ida";
import DocumentItem from "./DocumentItem";
import DocumentLPA from "./DocumentLPA";
import DocumentPrePlanning from "./DocumentPrePlanning";
import DocumentPrePlanLetter from "./DocumentPrePlanningLetter";
import DocumentWill from "./DocumentTestament";
import LoaderScreen from "./LoaderScreen";
import SectionDivider from "./SectionDivider";

export default function DocumentList() {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<IdaDocumentType[]>();
  const [contacts, setContracts] = useState<IdaDocumentType[]>();
  const [otherDocuments, setOtherDocuments] = useState<IdaDocumentType[]>();

  const { user } = useUserContext();
  const { flow } = useFlowContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }

    void getDocumentList(user.accessToken)
      .then((data) => {
        if (data.length === 0) {
          setDocuments([]);
          return;
        }

        const docs = data.map((doc) => doc.parentDocument);
        setDocuments(docs);

        const contracts = docs.filter((d) =>
          FLOW_CONTRACT_IDS.includes(d.documentTypeId)
        );
        setContracts(contracts);

        const otherDocs = docs.filter(
          (d) =>
            !FLOW_DOC_IDS.includes(d.documentTypeId) &&
            !FLOW_CONTRACT_IDS.includes(d.documentTypeId)
        );
        setOtherDocuments(otherDocs);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [user.accessToken]);

  const showWill = () => {
    return flow.docs.find(
      (d) => d.id === FLOW_DOC_WILL_ID && (d.ready || d.view)
    );
  };

  const hasWillDoc = () => {
    return (
      documents && documents.find((d) => d.documentTypeId === FLOW_DOC_WILL_ID)
    );
  };

  const renderWillDoc = () => {
    const will = documents.find((d) => d.documentTypeId === FLOW_DOC_WILL_ID);
    if (!will) {
      return;
    }
    return (
      <DocumentItem
        title={will.documentTypeName}
        completed={true}
        onClickHandle={() => navigate(`/documents/${FLOW_DOC_WILL_TYPE}`)}
        date={formatDocumentDate(will.created)}
      />
    );
  };

  const showLPA = () => {
    return flow.docs.find(
      (d) => d.id === FLOW_DOC_LPA_ID && (d.ready || d.view)
    );
  };

  const hasLPADoc = () => {
    return (
      documents && documents.find((d) => d.documentTypeId === FLOW_DOC_LPA_ID)
    );
  };

  const renderLPADoc = () => {
    const doc = documents.find((d) => d.documentTypeId === FLOW_DOC_LPA_ID);
    if (!doc) {
      return;
    }
    return (
      <DocumentItem
        title={doc.documentTypeName}
        completed={true}
        onClickHandle={() => navigate(`/documents/${FLOW_DOC_LPA_TYPE}`)}
        date={formatDocumentDate(doc.created)}
      />
    );
  };

  const showPreplanningLetter = () => {
    const hasDoc =
      documents &&
      documents.find((d) => d.documentTypeId === FLOW_DOC_PP_LETTER_ID);
    if (hasDoc) {
      return true;
    }

    if (!flow || !flow.docs) {
      return false;
    }

    return flow.docs.find(
      (d) => d.id === FLOW_DOC_PP_LETTER_ID && (d.ready || d.view)
    );
  };

  const showPreplanning = () => {
    const hasDoc =
      documents && documents.find((d) => d.documentTypeId === FLOW_DOC_PP_ID);
    if (hasDoc) {
      return true;
    }

    if (!flow || !flow.docs) {
      return false;
    }

    return flow.docs.find(
      (d) => d.id === FLOW_DOC_PP_ID && (d.ready || d.view)
    );
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  if (!documents) {
    return null;
  }

  return (
    <>
      {(hasLPADoc() ||
        hasWillDoc() ||
        showWill() ||
        (contacts && contacts.length > 0)) && (
        <div className="mb-8 lg:mb-12">
          <SectionDivider title="Avtal & Dokument" wrapperClasses="!mb-6" />
          <div className="grid auto-rows-fr grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {hasWillDoc() && !showWill() && renderWillDoc()}
            {showWill() && <DocumentWill documents={documents} />}

            {hasLPADoc() && !showLPA() && renderLPADoc()}
            {showLPA() && <DocumentLPA documents={documents} />}

            {contacts &&
              contacts.length > 0 &&
              contacts.map((doc) => (
                <DocumentItem
                  key={doc.id}
                  title={doc.documentTypeName}
                  completed={true}
                  onClickHandle={() => navigate(`/documents/${doc.id}`)}
                  date={formatDocumentDate(doc.created)}
                />
              ))}
          </div>
        </div>
      )}

      {(showPreplanningLetter() || showPreplanning()) && (
        <div className="mb-8 lg:mb-12">
          <SectionDivider title="Din förplanering" wrapperClasses="!mb-6" />
          <div className="grid auto-rows-fr grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {showPreplanningLetter() && (
              <DocumentPrePlanLetter documents={documents} />
            )}
            {showPreplanning() && <DocumentPrePlanning documents={documents} />}
          </div>
        </div>
      )}

      {otherDocuments && otherDocuments.length > 0 && (
        <div>
          <SectionDivider title="diverse dokument" wrapperClasses="!mb-6" />
          <div className="grid auto-rows-fr grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {otherDocuments.map((doc) => (
              <DocumentItem
                key={doc.id}
                title={doc.documentTypeName}
                completed={true}
                onClickHandle={() => navigate(`/documents/${doc.id}`)}
                date={formatDocumentDate(doc.created)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
