import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FLOW_DOC_PP_ID, FLOW_DOC_PP_TYPE } from "../services/flow";
import { formatDocumentDate, isDesktopView } from "../services/utils";
import { IdaDocumentType } from "../types/ida";
import DocumentItem from "./DocumentItem";

type PropsType = {
  documents: IdaDocumentType[];
};

const pdfIcon = new URL(
  "../assets/images/pdf-flower-white.png",
  import.meta.url
);

export default function DocumentPrePlanning({ documents }: PropsType) {
  const [docWill, setDocWill] = useState();
  const [date, setDate] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const doc = documents.find((d) => d.documentTypeId === FLOW_DOC_PP_ID);
    if (doc) {
      setDocWill(doc);
    }
  }, [documents]);

  useEffect(() => {
    if (docWill) {
      const dt = formatDocumentDate(docWill.created);
      setDate(dt);
    }
  }, [docWill]);

  const onClickHandle = () => {
    navigate(`/documents/${FLOW_DOC_PP_TYPE}`);
    return;
  };

  return (
    <>
      <DocumentItem
        title="Förplanering"
        completed={true}
        onClickHandle={onClickHandle}
        date={date}
        icon={pdfIcon}
        iconWidth={isDesktopView() ? 66 : 34}
        id="read_preplan_button"
      />
    </>
  );
}
