import { useFlowContext } from "../../context/FlowContext";

export default function DataSharingConsent({ question }) {
  const { flow } = useFlowContext();
  let text =
    "Genom att ge oss tillåtelse att dela den information du angett om tillgångar och skulder med din finansiella rådgivare kan du få skräddarsydda tips och rekommendationer inom ekonomi och försäkringar. Allt blir helt baserat på din livssituation och dina behov. Datan kommer inte att användas eller delas med utomstående tredje part, den är trygg och säker med oss, för ditt bästa.";

  if (flow && flow.pkg && flow.pkg === 112) {
    text =
      "Genom att ge oss tillåtelse att dela den information du angett om tillgångar och skulder med Svensk Hypotekspension kan du få skräddarsydda tips och rekommendationer. Allt blir helt baserat på din livssituation och dina behov. Datan kommer inte att användas eller delas med utomstående tredje part, den är trygg och säker med oss, för ditt bästa.";
  }

  return (
    <div className="mx-3 mt-5 border-t border-black pt-5 lg:mx-auto lg:mt-10 lg:pt-10">
      <div className="mb-4 font-interlight text-[24px] italic text-theme-tertiary1 lg:text-[42px]">
        Få bättre rekommendationer!
      </div>
      <div className="mb-4 font-interlight text-theme-neutral4">{text}</div>
      <div className="consent-radio lg:ml-12">{question}</div>
    </div>
  );
}
