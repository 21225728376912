import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowContext, useFlowDispatchContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import { FLOW_DOC_LPA_ID, FLOW_DOC_LPA_TYPE } from "../services/flow";
import { formatDocumentDate } from "../services/utils";
import { IdaDocumentType } from "../types/ida";
import DocumentItem from "./DocumentItem";

type PropsType = {
  documents: IdaDocumentType[];
};

export default function DocumentLPA({ documents }: PropsType) {
  const [completed, setCompleted] = useState(false);
  const [docWill, setDocWill] = useState();
  const [date, setDate] = useState();

  const { user } = useUserContext();
  const { flow, checkLPAPersonsForValidPersonalNumbers } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  const navigate = useNavigate();

  useEffect(() => {
    const result = checkLPAPersonsForValidPersonalNumbers();
    setCompleted(result);
  }, [flow.values]);

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const doc = documents.find((d) => d.documentTypeId === FLOW_DOC_LPA_ID);
    if (doc) {
      setDocWill(doc);
    }
  }, [documents]);

  useEffect(() => {
    if (docWill) {
      const dt = formatDocumentDate(docWill.created);
      setDate(dt);
    }
  }, [docWill]);

  const onClickHandle = () => {
    if (completed) {
      navigate(`/documents/${FLOW_DOC_LPA_TYPE}`);
      return;
    }

    dispatchFlow({
      type: "set-section-start",
      payload: { section: 102, accessToken: user.accessToken },
    });
    navigate("/flow");
  };

  return (
    <>
      <DocumentItem
        title="Framtidsfullmakt"
        completed={completed}
        onClickHandle={onClickHandle}
        date={date}
        progress={90}
        id={completed ? "read_lpa_button" : "finish_lpa_90%_button"}
      />
    </>
  );
}
