import React from "react";
import { PropsWithChildren } from "react";

import { getQuoteIcon } from "../services/icons";
import { isDesktopView } from "../services/utils";

export function Quote({ children }: PropsWithChildren) {
  return (
    <div className="relative max-w-2xl p-5 lg:p-0" data-testid="quote">
      {isDesktopView() && (
        <div className="absolute z-0">{getQuoteIcon("#C3A2A1")}</div>
      )}
      {!isDesktopView() && (
        <div className="absolute z-0">
          {getQuoteIcon("#C3A2A1", "56", "100%")}
        </div>
      )}
      <div className="relative z-10 pt-[28px] pl-[28px] font-interlight text-[24px] italic leading-[28px] text-[#43262D] lg:pt-[50px] lg:pl-[55px] lg:text-[42px] lg:leading-[50px]">
        {children}
      </div>
    </div>
  );
}
