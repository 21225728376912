import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowContext, useFlowDispatchContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import { FLOW_DOC_WILL_ID, FLOW_DOC_WILL_TYPE } from "../services/flow";
import { formatDocumentDate } from "../services/utils";
import { IdaDocumentType } from "../types/ida";
import DocumentItem from "./DocumentItem";

type PropsType = {
  documents: IdaDocumentType[];
};

export default function DocumentTestament({ documents }: PropsType) {
  const [completed, setCompleted] = useState(false);
  const [docWill, setDocWill] = useState();
  const [date, setDate] = useState();

  const { user } = useUserContext();
  const { flow } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (documents.length === 0) {
      return;
    }
    const doc = documents.find((d) => d.documentTypeId === FLOW_DOC_WILL_ID);
    if (doc) {
      setWillCompleted();
      setDocWill(doc);
    }
  }, [documents]);

  useEffect(() => {
    if (docWill) {
      const dt = formatDocumentDate(docWill.created);
      setDate(dt);
    }
  }, [docWill]);

  useEffect(() => {
    if (flow.session?.status === "finished" && user.accessToken) {
      setWillCompleted();
    }
  }, [flow, user.accessToken]);

  const setWillCompleted = () => {
    const doc = flow.docs.find((d) => d.type === FLOW_DOC_WILL_TYPE);
    if (!doc) {
      setCompleted(false);
      return;
    }
    setCompleted(doc.ready);
  };

  const onClickHandle = () => {
    if (completed) {
      navigate(`/documents/${FLOW_DOC_WILL_TYPE}`);
      return;
    }

    dispatchFlow({
      type: "set-section-start",
      payload: { section: 100, accessToken: user.accessToken },
    });
    navigate("/flow");
  };

  return (
    <DocumentItem
      title="Testamente"
      completed={completed}
      onClickHandle={onClickHandle}
      date={date}
    />
  );
}
