import React, { useState } from "react";

import AccordionItem from "./AccordionItem";

export default function Accordion({
  items,
  color,
  open,
  id = "",
}: {
  items: any;
  color?: string;
  open?: number;
  id?: string;
}) {
  const [currentIdx, setCurrentIdx] = useState(open);
  const btnOnClick = (idx: number) => {
    setCurrentIdx((currentValue) => (currentValue !== idx ? idx : -1));
  };

  return (
    <div className="accordion border-b border-[#CECABA]" id={id}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */}
      {items.map((item: any, idx: number) => (
        <AccordionItem
          key={idx}
          color={color}
          data={item}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx)}
        />
      ))}
    </div>
  );
}
