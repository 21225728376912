import React from "react";

type PropsType = {
  title: string;
  subtitle: string;
  mobileHeader?: boolean;
};

export default function RightColumnHeader({
  title,
  subtitle,
  mobileHeader,
}: PropsType) {
  let color = "text-theme-neutral4";
  if (mobileHeader) {
    color = "text-theme-neutral2";
  }

  return (
    <>
      {title && (
        <div
          className={`mb-3 font-intermedium text-[12px] uppercase leading-none tracking-[0.2em] ${color}`}
        >
          {title}
        </div>
      )}
      {subtitle && (
        <div
          className={`font-intermedium uppercase leading-[24px] tracking-[0.25em] ${color}`}
        >
          {subtitle}
        </div>
      )}
    </>
  );
}
