import React, { useEffect, useState } from "react";

import LoaderScreen from "../components/LoaderScreen";
import { useFlowContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";

export default function FlowRestartPage() {
  const [loading, setLoading] = useState(true);
  const { restartFlow } = useFlowContext();
  const { user } = useUserContext();

  useEffect(() => {
    const rsParam = new URLSearchParams(window.location.search).get("rs");
    if (user.accessToken && rsParam === "dF23nk0") {
      void restartFlow();
    }
  }, [user.accessToken]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return null;
}
