import React from "react";
import { Link } from "react-router-dom";

type PropsType = {
  title: string;
  color?: string;
  vertical?: boolean;
  isActive?: boolean;
  id?: string;
  icon: JSX.Element;
};

type LinkType = PropsType & {
  to: string;
};

type ClickType = PropsType & {
  onClickHandle: () => void;
};

export default function IconLink(props: ClickType | LinkType) {
  const {
    icon,
    title,
    color = "#FFFEFB",
    vertical = false,
    isActive = false,
    id = "",
  } = props;

  let onClickHandle;
  if ("onClickHandle" in props) {
    onClickHandle = props.onClickHandle;
  }

  let to = "";
  if ("to" in props) {
    to = props.to;
  }

  let orientation = "flex-row";
  if (vertical) {
    orientation = "flex-col";
  }

  let labelClasses = "text-[10px] lg:text-[14px]";
  if (vertical) {
    labelClasses = "text-[10px]";
  }

  let activeIndicator = "bottom-[-12px] hidden group-hover:block";
  if (vertical) {
    activeIndicator = "hidden top-[-16px] border-theme-primary1";
  }
  if (isActive) {
    activeIndicator += " !block";
  }

  const classes = `flex ${orientation} items-center gap-2 border-[1px] border-transparent p-0 leading-none lg:gap-3 cursor-pointer`;
  const renderLinkContent = () => (
    <>
      {icon}
      <span
        className={`font-intermedium uppercase leading-none tracking-[0.12em] ${labelClasses}`}
        style={{ color: color }}
      >
        {title}
      </span>
    </>
  );

  return (
    <div className="group relative">
      {onClickHandle ? (
        <div onClick={onClickHandle} className={classes} id={id}>
          {renderLinkContent()}
        </div>
      ) : (
        <Link to={to} className={classes} id={id}>
          {renderLinkContent()}
        </Link>
      )}
      <div
        className={`absolute w-full rounded-full border-2 ${activeIndicator}`}
      />
    </div>
  );
}
