import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../components/Button";
import List from "../components/flow/List";
import FlowHead from "../components/FlowHead";
import FlowRestart from "../components/FlowRestart";
import LoaderScreen from "../components/LoaderScreen";
import Modal from "../components/Modal";
import PdfPreview from "../components/PdfPreview";
import config from "../config";
import { useFlowContext, useFlowDispatchContext } from "../context/FlowContext";
import { RequireUserAuth } from "../context/UserContext";
import { useUserContext } from "../context/UserContext";
import {
  FLOW_DOC_LPA_ID,
  FLOW_DOC_LPA_TYPE,
  FLOW_DOC_PP_ID,
  FLOW_DOC_PP_LETTER_ID,
  FLOW_DOC_PP_LETTER_TYPE,
  FLOW_DOC_PP_TYPE,
  FLOW_DOC_WILL_ID,
  FLOW_DOC_WILL_TYPE,
} from "../services/flow";
import {
  getCheckIcon,
  getCheckmarkIcon,
  getCloseIcon,
  getDownloadIcon,
  getFlowCloseIcon,
  getRestartIcon,
} from "../services/icons";
import {
  getDocFromSession,
  getDocumentByID,
  getDocumentList,
  sendInternalEmail,
} from "../services/ida";
import {
  formatDocumentDate,
  isDesktopView,
  isIOSSafari,
} from "../services/utils";
import { IdaDocumentType } from "../types/ida";

function DocumentItemPage() {
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState<IdaDocumentType>();
  const [docBlob, setDocBlob] = useState<Blob>();
  const [previewFile, setPreviewFile] = useState<FileReader["result"]>(null);
  const [date, setDate] = useState<string>();
  const [typeID, setTypeID] = useState<number>();
  const [docID, setDocID] = useState<number>();
  const [title, setTitle] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [processSteps, setProcessSteps] = useState<{ statusText: string }[]>();

  const navigate = useNavigate();

  const { type } = useParams<{
    type: string;
  }>();

  const { user } = useUserContext();
  const { flow } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    if (!type) {
      return;
    }
    switch (type) {
      case FLOW_DOC_WILL_TYPE:
        setTypeID(FLOW_DOC_WILL_ID);
        setTitle("Testamente");
        break;
      case FLOW_DOC_PP_LETTER_TYPE:
        setTypeID(FLOW_DOC_PP_LETTER_ID);
        setTitle("Brev till eftervärlden");
        break;
      case FLOW_DOC_PP_TYPE:
        setTypeID(FLOW_DOC_PP_ID);
        setTitle("Förplanering");
        break;
      case FLOW_DOC_LPA_TYPE:
        setTypeID(FLOW_DOC_LPA_ID);
        setTitle("Framtidsfullmakt");
        break;
      default: {
        const id = parseInt(type);
        if (id && id > 0) {
          setDocID(id);
          break;
        }
        navigate("/documents");
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (user.accessToken && docID) {
      fetchDocByID(docID);
    }
  }, [user.accessToken, docID]);

  useEffect(() => {
    if (user.accessToken && typeID) {
      fetchDoc();
    }
  }, [user.accessToken, typeID]);

  useEffect(() => {
    if (!doc) {
      return;
    }
    const dt = formatDocumentDate(doc.created);
    setDate(dt);

    if (doc.processSteps) {
      setProcessSteps(doc.processSteps);
    }
  }, [doc]);

  useEffect(() => {
    if (docBlob) {
      renderPreview();
    }
  }, [docBlob]);

  useEffect(() => {
    if (flow.section === 101 && flow.status === "finished") {
      setProcessSteps([{ statusText: "Skickat för påskrift" }]);
    }
  }, [flow.status]);

  const getSessionDoc = () => {
    if (!user.accessToken) {
      return;
    }
    void getDocFromSession(user.accessToken, flow.session?.id, typeID)
      .then((data) => {
        const blob = new Blob([data], { type: "application/pdf" });
        if (blob) {
          setDocBlob(blob);
        }
      })
      .catch((e) => console.log(e));
  };

  const renderPreview = () => {
    if (!docBlob) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(docBlob);
    reader.onloadend = function () {
      const base64String = reader.result;
      if (base64String) {
        setPreviewFile(base64String);
      }
    };
  };

  const downloadDocHandler = () => {
    if (!docBlob || !user.profile) {
      return;
    }

    const href = URL.createObjectURL(docBlob);

    if (!isDesktopView() && isIOSSafari()) {
      window.open(href, "_self");
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.target = "_blank";
    if (isDesktopView()) {
      const parts = [
        title,
        user.profile.firstName,
        user.profile.lastName,
      ].reduce((acc, p) => {
        return [
          ...acc,
          p
            .replace(/([;'"`:@\\/]+)/gi, "")
            .split(" ")
            .join("_"),
        ];
      }, [] as string[]);
      downloadLink.download = `${parts.join("_")}.pdf`;
    }
    downloadLink.rel = "noreferrer";
    downloadLink.click();
  };

  const onShowConfirmModalClose = () => {
    if (!user.accessToken) {
      return;
    }
    dispatchFlow({
      type: "set-section-start",
      payload: { section: 101, accessToken: user.accessToken },
    });
    setShowConfirmModal(true);
  };

  const onConfirmModalClose = () => {
    if (!validateAddressForm() || !user.profile || !user.accessToken) {
      return;
    }
    setLoading(true);

    const addressValue = flow.values.find(
      (v) => v.name === "address-will-address"
    );
    const address = addressValue ? addressValue.value.toString() : "";

    const postcodeValue = flow.values.find(
      (v) => v.name === "address-will-postcode"
    );
    const postcode = postcodeValue ? postcodeValue.value.toString() : "";

    const areaValue = flow.values.find((v) => v.name === "address-will-area");
    const area = areaValue ? areaValue.value.toString() : "";

    const name = `${user.profile.firstName} ${user.profile.lastName}`;

    const body = [
      `Hej,`,
      `Kunden ${name} (${user.profile.personNumber}) har nått sista steget i processen för sitt nyskapade testamente och önskar att vi skickar det för signering.`,
      `Du kan nu hantera förfrågan genom att manuellt aktivera Azets-flödet i IDA.`,
      `IDA Kund-id: ${user.profile.id}\nIDA Dokument-id: ${
        doc ? doc.id : ""
      }\nAdress:\n${name}\n${address}\n${postcode} ${area}`,
      `Ha en fin dag!`,
    ];
    sendInternalEmail(
      user.accessToken,
      "En kund är redo att signera sitt testamente",
      body.join("\n\n")
    )
      .then(() => {
        setShowConfirmModal(false);
        if (!user.accessToken) {
          return;
        }
        dispatchFlow({
          type: "set-status",
          payload: { status: "finished", accessToken: user.accessToken },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateAddressForm = () => {
    const errs = flow.errors ? flow.errors : [];
    return Object.values(errs).length === 0;
  };

  const onEditAddress = () => {
    if (editAddress) {
      setEditAddress(false);
      return;
    }
    if (user.accessToken) {
      dispatchFlow({
        type: "set-section-start",
        payload: { section: 101, accessToken: user.accessToken },
      });
    }
    setEditAddress(true);
  };

  const fetchDoc = () => {
    if (!user.accessToken) {
      return;
    }

    void getDocumentList(user.accessToken)
      .then((data) => {
        if (data.length === 0) {
          getSessionDoc();
          return data;
        }

        const parentDocIndex = data.findIndex(
          (d) => d.parentDocument.documentTypeId === typeID
        );
        if (parentDocIndex === -1 || !data[parentDocIndex]) {
          getSessionDoc();
          return;
        }

        let doc = data[parentDocIndex].parentDocument;
        const linkedDocs = data[parentDocIndex].linkedDocuments;
        if (linkedDocs && linkedDocs.length > 0) {
          doc = linkedDocs[linkedDocs.length - 1];
        }

        if (!user.accessToken) {
          return;
        }
        getDocumentByID(user.accessToken, doc.id)
          .then((dt) => {
            setDoc({
              ...dt,
              id: doc.id,
              processSteps: data[parentDocIndex].parentDocument.processSteps,
            });
            void fetch(dt.base64Content).then((d) => {
              d.blob()
                .then((b) => {
                  setDocBlob(b);
                })
                .catch((e) => console.log(e));
            });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const fetchDocByID = (id: number) => {
    if (!user.accessToken) {
      return;
    }

    getDocumentByID(user.accessToken, id)
      .then((data) => {
        setDoc({ ...data, id: id });
        setTitle(data.documentTypeName);
        void fetch(data.base64Content).then((d) => {
          void d.blob().then((b) => {
            setDocBlob(b);
          });
        });
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    const prefix = typeID === FLOW_DOC_LPA_ID ? "Min" : "Mitt";
    return <FlowHead title={`${prefix} ${title}`} />;
  };

  if (loading || !user.info || !previewFile) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      {isDesktopView() && (
        <div
          className="absolute right-[12px] top-[24px] cursor-pointer lg:right-[24px]"
          onClick={() => navigate("/documents")}
        >
          {getFlowCloseIcon("#E8E3D2")}
        </div>
      )}

      <div className="grow">
        {renderTitle()}
        <div className="flex flex-col gap-8 lg:flex-row lg:gap-20">
          <div className="flex flex-1 flex-col gap-1">
            {previewFile && (
              <div
                className="relative mx-auto flex cursor-pointer self-end lg:mx-0"
                onClick={() => setShowPreviewModal(true)}
              >
                <div className="h-full">
                  <PdfPreview
                    file={previewFile}
                    width={!isDesktopView() ? window.innerWidth - 230 : 500}
                    displayPages={1}
                    theme="border"
                  />
                </div>
              </div>
            )}
            <div
              className="flex w-full cursor-pointer items-center justify-center gap-2 lg:justify-end"
              onClick={downloadDocHandler}
              id="download_will_button"
            >
              <div className="border-b-[1px] border-b-theme-primary1 pb-[2px] font-intermedium text-[12px] uppercase leading-none tracking-[0.12em] text-theme-primary1 lg:text-[14px]">
                ladda ned
              </div>
              {getDownloadIcon()}
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-6 lg:items-start">
            <div className="max-w-[340px]">
              <div className="flex flex-col items-center gap-1.5 lg:items-start">
                <div className="font-intermedium text-[20px] text-theme-primary1">
                  {title}
                </div>
                <div className="flex flex-row items-center gap-3 text-[13px]">
                  <div className="flex items-center gap-1 text-theme-func-green">
                    <div className="font-intermedium">Klart</div>
                    {getCheckmarkIcon("#467655", "14", "100%")}
                  </div>
                  {date && (
                    <div className="font-interlight text-theme-neutral4">
                      Skapat {date}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {typeID === FLOW_DOC_WILL_ID &&
              !processSteps &&
              flow.docs.find((d) => d.id === FLOW_DOC_WILL_ID)?.ready ===
                true && (
                <Button
                  onClick={onShowConfirmModalClose}
                  theme="light-green"
                  rightArrow={false}
                  title="Skicka till mig för påskrift"
                  cssClasses="w-full lg:w-auto"
                  id="send_will_for_signing_button"
                />
              )}
            {typeID === FLOW_DOC_WILL_ID && processSteps && (
              <div className="font-intermedium">
                <span className="text-theme-primary1">Processtatus:</span>{" "}
                <span className="italic text-theme-func-green">
                  {processSteps.pop()?.statusText}
                </span>
              </div>
            )}

            {config.stage !== "production" && <FlowRestart hardReset={true} />}
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <Modal onClose={() => setShowConfirmModal(false)} title="">
          <div className="flex flex-col items-center bg-theme-white p-5 lg:min-w-[500px] lg:p-0">
            <div className="mb-4 font-intermedium text-[13px] text-theme-neutral4">
              Vi kommer skicka följande:
            </div>
            <div className="flex w-full flex-col-reverse items-center gap-2 lg:flex-row lg:gap-8">
              {previewFile && (
                <div className="flex flex-1 justify-end">
                  <div className="">
                    <PdfPreview
                      file={previewFile}
                      width={isDesktopView() ? 200 : window.innerWidth - 250}
                      displayPages={1}
                      theme="border"
                    />
                  </div>
                </div>
              )}
              <div className="flex-1">
                <div className="font-intermedium text-[18px] text-[#5F3741]">
                  Testamente
                </div>
                {date && (
                  <div className="mb-2 font-interlight text-[13px] text-theme-neutral4 lg:mb-4">
                    Skapat {date}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-5 flex max-w-[350px] flex-col items-center">
              <div className="font-intermedium text-[13px] text-theme-neutral4">
                Till:
              </div>
              <div className="flex flex-col items-center">
                {editAddress && (
                  <div className="my-5">
                    <List
                      rightCol={false}
                      nextButton={false}
                      runValidate={true}
                    />
                  </div>
                )}
                {!editAddress && (
                  <>
                    <div>
                      {flow.values
                        .find((v) => v.name === "address-will-address")
                        ?.value.toString()}
                    </div>
                    <div>
                      {flow.values
                        .find((v) => v.name === "address-will-postcode")
                        ?.value.toString()}{" "}
                      {flow.values
                        .find((v) => v.name === "address-will-area")
                        ?.value.toString()}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Button
              onClick={onConfirmModalClose}
              title="bekräfta"
              cssClasses="mb-4"
              id="send_will_for_signing_confirm_button"
            />
            <div
              className="cursor-pointer border-b-[1px] border-b-theme-primary1 pb-[2px] font-intermedium text-[14px] leading-none text-theme-primary1"
              onClick={onEditAddress}
            >
              Ändra adress
            </div>
          </div>
        </Modal>
      )}
      {showPreviewModal && (
        <Modal onClose={() => setShowPreviewModal(false)} title="">
          {previewFile && (
            <div className="flex justify-center">
              <PdfPreview
                file={previewFile}
                width={!isDesktopView() ? window.innerWidth - 30 : undefined}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default function DocumentItemPageWrapper() {
  return (
    <RequireUserAuth>
      <DocumentItemPage />
    </RequireUserAuth>
  );
}
