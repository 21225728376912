import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import FlowHead from "../components/FlowHead";
import LoaderScreen from "../components/LoaderScreen";
import ProfileEditForm from "../components/ProfileEditForm";
import VaultHead from "../components/VaultHead";
import { RequireUserAuth } from "../context/UserContext";
import { useUserContext } from "../context/UserContext";
import {
  getFlowCloseIcon,
  getGearsIcon,
  getVaultLogo,
} from "../services/icons";
import { isDesktopView } from "../services/utils";

function ProfileEdit() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user, fetchProfileData } = useUserContext();

  useEffect(() => {
    openEditProfileForm();
  }, []);

  const getProfileData = () => {
    if (!user.accessToken) {
      return;
    }
    void fetchProfileData(user.accessToken);
  };

  const openEditProfileForm = () => {
    if (!user.accessToken) {
      return;
    }
    setLoading(true);
    void fetchProfileData(user.accessToken).finally(() => {
      setLoading(false);
    });
  };

  if (loading || !user.info) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <VaultHead
        title="Mitt konto"
        icon={getGearsIcon("#0C2632", "22px", "100%")}
      />

      <div className="grow">
        <div className="max-w-3xl">
          <ProfileEditForm onSuccessHandler={getProfileData} />
        </div>
      </div>
    </>
  );
}

export default function ProfileEditWrapper() {
  return (
    <RequireUserAuth>
      <ProfileEdit />
    </RequireUserAuth>
  );
}
