import * as store from "store";
import cookieStorage from "store/storages/cookieStorage";

import { getDomainForCookie } from "./utils";

export const userKey = "fnxUser";
export const conversationKey = "fnxConv";
export const flowKey = "fnxFlow";
export const flowSessionKey = "fnxFlowSession";
export const partnerKey = "fnxPrt";
export const homeRedirectKey = "fnxHmRd";
export const userEmailKey = "fnxUserEmail";
export const ppCampKey = "fnxPPCamp";

export function getFromStorage<T>(key: string): T | undefined;
export function getFromStorage<T>(key: string, optionalDefaultValue: T): T;
export function getFromStorage<T>(key: string, optionalDefaultValue?: T) {
  return store.get(key, optionalDefaultValue) as T | undefined;
}

export function setInStorage<T>(key: string, data: T) {
  return store.set(key, data) as T;
}

export function setCookie(key: string, data: string, expDays = 1) {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();

  const domain = getDomainForCookie();
  if (!domain) {
    document.cookie = key + "=" + data + "; " + expires + "; path=/";
  }
  document.cookie =
    key + "=" + data + "; " + expires + "; path=/; domain=" + domain;
}

export function getCookie(key: string) {
  return cookieStorage.read(key);
}

export function removeFromStorage(key: string) {
  return store.remove(key);
}

export function removeCookie(key: string) {
  const domain = getDomainForCookie();
  if (!domain) {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  } else {
    domain
      .split(".")
      .reverse()
      .reduce((acc, i) => {
        if (i.length === 0) {
          return acc;
        }
        const d = `.${i}${acc}`;
        document.cookie =
          key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + d;
        return d;
      }, "");
  }

  return cookieStorage.remove(key);
}
