import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlowDispatchContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import { sendCmpEvent } from "../services/tracking";
import Button from "./Button";
import LoaderIcon from "./LoaderIcon";

export default function CheckoutForm({ cs, setPageData, price }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const dispatchFlow = useFlowDispatchContext();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case "succeeded":
              setSuccessful(true);
              setMessage("payment-status-success");
              break;
            case "processing":
              setMessage("payment-status-processing");
              break;
            case "requires_payment_method":
              setMessage("payment-status-try-again");
              break;
            default:
              setMessage("payment-status-unknown");
              break;
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    } else {
      stripe
        .retrievePaymentIntent(cs)
        .then(({ paymentIntent }) => {
          if (paymentIntent?.status === "succeeded") {
            setMessage("payment-status-already-succeeded");
            setSuccessful(true);
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    }
  }, [stripe]);

  useEffect(() => {
    if (!isSuccessful || !user.accessToken) {
      return;
    }

    if (user.profile) {
      void sendCmpEvent("Mail_5", user.profile.email);
    }
  }, [isSuccessful]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
      // redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (response.error && response.error.message) {
      setMessage(response.error.message);
    }

    setIsLoading(false);
  };

  const getPayButtonText = () => {
    return (
      <span id="button-text">
        {isLoading ? (
          <div className="flex w-full justify-center">
            <LoaderIcon size="w-6 h-6" />
          </div>
        ) : (
          "Betala"
        )}
      </span>
    );
  };

  if (isSuccessful && user.accessToken) {
    dispatchFlow({
      type: "update-payment-status",
      payload: { accessToken: user.accessToken, paymentStatus: true },
    });
    navigate("/vault");
    return null;
  }

  return (
    <>
      {fetchingStatus ? (
        <div className="mx-auto flex justify-center">
          <LoaderIcon size="w-6 h-6" />
        </div>
      ) : (
        <>
          <form
            id="payment-form"
            onSubmit={(e) => {
              void handleSubmit(e);
            }}
          >
            <PaymentElement id="payment-element" />
            <div className="mt-10 mb-8 flex flex-row items-start justify-between">
              <div>
                <div className="font-intermedium uppercase leading-none tracking-[0.25em] text-theme-primary1">
                  totalbelopp
                </div>
                <div className="font-interlight text-theme-primary1">
                  inkl. moms
                </div>
              </div>

              <div className="font-interbold text-[20px] italic text-theme-primary1 lg:text-[22px]">
                <span className="uppercase">{price}</span> kr
              </div>
            </div>
            <div className="flex">
              <Button
                onClick={() => {
                  if (user.profile) {
                    void sendCmpEvent("Mail_4_2", user.profile.email);
                  }
                }}
                title=""
                id="payment_betala"
                disabled={isLoading || !stripe || !elements}
                type="submit"
              >
                {getPayButtonText()}
              </Button>
            </div>
            {message !== "" && (
              <div id="payment-message" className="!text-left">
                {message}
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
}
