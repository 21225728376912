import React from "react";
import { useNavigate } from "react-router-dom";

import { FLOW_DOC_PP_LETTER_TYPE } from "../services/flow";
import { isDesktopView } from "../services/utils";
import { IdaDocumentType } from "../types/ida";

type PropsType = {
  documents: IdaDocumentType[];
};

const pdfIcon = new URL("../assets/images/pdf-flower.png", import.meta.url);

export default function DocumentPrePlanningLetter({ documents }: PropsType) {
  const navigate = useNavigate();

  const onClickHandle = () => {
    navigate(`/documents/${FLOW_DOC_PP_LETTER_TYPE}`);
    return;
  };

  const renderIcon = () => {
    const imgWidth = isDesktopView() ? 66 : 34;
    return <img src={pdfIcon.href} width={imgWidth} />;
  };

  return (
    <>
      <div
        className="flex cursor-pointer flex-row items-center justify-between gap-4 rounded-[4px] bg-theme-secondary4 p-4 lg:flex-col lg:justify-center lg:gap-5 lg:p-8"
        onClick={onClickHandle}
        id="read_letter_button"
      >
        <div>{renderIcon()}</div>
        <div className="flex flex-1 flex-col lg:items-center">
          <div className="font-intermedium text-theme-tertiary2 lg:text-center">
            *Ditt brev till eftervärlden*
          </div>
        </div>
      </div>
    </>
  );
}
