import React, { useEffect, useState } from "react";

import LoaderScreen from "../components/LoaderScreen";
import { useUserContext } from "../context/UserContext";
import {
  getProfileInfo,
  getUserDataByKey,
  getUserPackage,
} from "../services/ida";

export default function DebugPage() {
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState();
  const [packageData, setPackageData] = useState();
  const [dataStore, setDatastore] = useState();

  const { user } = useUserContext();

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }

    setLoading(true);
    void fetchData();
  }, [user.accessToken]);

  const fetchData = async () => {
    if (!user.accessToken) {
      return;
    }

    const profilePromise = getProfileInfo(user.accessToken)
      .then((data) => {
        setRawData(data);
      })
      .catch((e) => console.log(e));

    const packagePromise = getUserPackage(user.accessToken)
      .then((data) => {
        setPackageData(data);
      })
      .catch((e) => console.log(e));

    const datastorePromise = getUserDataByKey(user.accessToken, "flow")
      .then((data) => {
        setDatastore(data);
      })
      .catch((e) => console.log(e));

    await Promise.allSettled([
      profilePromise,
      datastorePromise,
      packagePromise,
    ]).then((values) => {
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <div className="w-full break-words">
        {user?.info && (
          <div>
            <div className="text-left text-[14px]">
              <div>Access token:</div>
              <code className="text-[12px]">{user.accessToken}</code>
            </div>
            {rawData && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/profile
                  <br />
                  {JSON.stringify(rawData, null, 4)}
                </code>
              </div>
            )}
            {dataStore && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/datastore
                  <br />
                  {JSON.stringify(dataStore, null, 4)}
                </code>
              </div>
            )}
            {packageData && (
              <div className="mt-10 whitespace-pre-wrap text-left leading-none">
                <code className="text-[12px]">
                  GET /api/v1/public/customer/package
                  <br />
                  {JSON.stringify(packageData, null, 4)}
                </code>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
