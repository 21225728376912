import React, { useEffect, useState } from "react";

import Button from "../components/Button";
import LoaderScreen from "../components/LoaderScreen";
import VaultHead from "../components/VaultHead";
import { useFlowContext } from "../context/FlowContext";
import { useUserContext } from "../context/UserContext";
import { flowData } from "../services/flow";
import { getDocumentIcon, getFamilyTreeIcon } from "../services/icons";
import { createFlow, createSession, getDocFromSession } from "../services/ida";
import {
  generateRandomString,
  isDesktopView,
  isIOSSafari,
} from "../services/utils";

export default function FamilyPage() {
  const [loading, setLoading] = useState(false);
  const [flowId, setFlowId] = useState(1);
  const [sessionId, setSessionId] = useState(false);

  const { user } = useUserContext();
  const { flow } = useFlowContext();

  useEffect(() => {
    console.log("flowId", flowId);
  }, [flowId]);

  useEffect(() => {
    console.log("sessionId", sessionId);
  }, [sessionId]);

  const createFlowHandler = () => {
    createFlow(user.accessToken, flowData).then((data) => {
      if (data.id) {
        setFlowId(data.id);
      }
    });
  };

  const createSessionHandler = () => {
    const data = { ...flow };
    data.profile = {
      email: user.profile ? user.profile.email : "",
      personalNumber: user.profile ? user.profile.personNumber : "",
      firstName: user.profile ? user.profile.firstName : "",
      lastName: user.profile ? user.profile.lastName : "",
    };
    const userId = user.profile ? user.profile.id : "";
    createSession(user.accessToken, userId, flowId, data).then((data) => {
      if (data.id) {
        setSessionId(data.id);
      }
    });
  };

  const getDocHandler = () => {
    void getDocFromSession(user.accessToken, sessionId, 11).then((data) => {
      const blob = new Blob([data], { type: "application/pdf" });
      const href = URL.createObjectURL(blob);

      if (!isDesktopView() && isIOSSafari()) {
        window.open(href, "_self");
        return;
      }

      const downloadLink = document.createElement("a");
      downloadLink.href = href;
      downloadLink.target = "_blank";
      if (isDesktopView()) {
        downloadLink.download = `testamente_${generateRandomString()}.pdf`;
      }
      downloadLink.rel = "noreferrer";
      downloadLink.click();
    });
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      <VaultHead title="Din familj" icon={getFamilyTreeIcon("#0C2632")} />
      <div>Din familj</div>
      {/*<div className="flex flex-col gap-5">*/}
      {/*  <div>*/}
      {/*    <Button onClick={createFlowHandler} title="Create flow" />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <Button onClick={createSessionHandler} title="Create session" />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <Button onClick={getDocHandler} title="Get testamente" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}
