import config from "../config";

export const financialOverview: string = config.feature_financial_overview;
export const errorReporting: string = config.feature_error_reporting;

type FeatureNamesType = "financial-overview" | "error-reporting";

export function isFeatureEnabled(name: FeatureNamesType) {
  switch (name) {
    case "financial-overview": {
      return financialOverview === "1";
    }
    case "error-reporting": {
      return errorReporting === "1";
    }
    default: {
      return false;
    }
  }
}
