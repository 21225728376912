import React from "react";

import {
  getButtonArrow,
  getCheckmarkIcon,
  getFileIcon,
} from "../services/icons";
import { isDesktopView } from "../services/utils";
import ProgressBar from "./ui/ProgressBar";

type PropsType = {
  title: string;
  completed: boolean;
  onClickHandle: () => void;
  id?: string;
  date?: string;
  icon?: URL;
  iconWidth?: number;
  progress?: number;
};

const pdfIcon = new URL("../assets/images/pdf.png", import.meta.url);

export default function DocumentItem({
  title,
  completed,
  onClickHandle,
  date,
  icon,
  iconWidth,
  progress,
  id = "",
}: PropsType) {
  let wrapperClasses = "bg-white";
  let textClasses = "text-theme-primary1";
  let arrowColor = "#0C2632";
  if (completed) {
    wrapperClasses = "bg-theme-primary1";
    textClasses = "text-white";
    arrowColor = "#ffffff";
  }

  const renderArrowIcon = () => {
    return getButtonArrow(arrowColor, "18", "100%");
  };

  const renderIcon = () => {
    let imgWidth = "46";
    let iWidth = "38";
    if (!isDesktopView()) {
      imgWidth = "34";
      iWidth = "26";
    }
    if (completed) {
      return (
        <img
          src={icon ? icon.href : pdfIcon.href}
          width={iconWidth ? iconWidth : imgWidth}
        />
      );
    }

    return getFileIcon("#0C2632", iWidth, "100%");
  };

  return (
    <div
      className={`flex cursor-pointer flex-row items-center justify-between gap-4 rounded-[4px] p-4 lg:flex-col lg:justify-center lg:gap-5 lg:p-8 ${wrapperClasses}`}
      onClick={onClickHandle}
      id={id}
    >
      <div>{renderIcon()}</div>
      <div className="flex flex-1 flex-col lg:items-center">
        <div className="flex flex-row gap-2">
          <div
            className={`font-intermedium text-theme-primary1 ${textClasses}`}
          >
            {title}
          </div>
          {isDesktopView() && <div>{renderArrowIcon()}</div>}
        </div>
        <div className="flex w-full flex-row items-center gap-4 text-[13px] lg:justify-center">
          {completed ? (
            <>
              <div className="flex items-center gap-1 text-[#A0C1AB]">
                <div>Klart</div>
                {getCheckmarkIcon("#A0C1AB", "14", "100%")}
              </div>
              {date && (
                <div className="text-[13px] text-theme-neutral2">
                  Skapat {date}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="w-[100px]">
                <ProgressBar
                  value={progress ? progress : 75}
                  theme="document"
                />
              </div>
              <div className="shrink-0 text-[#736F62]">Gör klart</div>
            </>
          )}
        </div>
      </div>
      {!isDesktopView() && <div>{renderArrowIcon()}</div>}
    </div>
  );
}
