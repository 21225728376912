import React, { ChangeEventHandler } from "react";

import { getArrowIcon } from "../../services/icons";
import ErrorFormMessage from "../ErrorFormMessage";
import QuestionTitle from "../flow/QuestionTitle";

type PropsType = {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  id?: string;
  placeholder?: string;
  customClasses?: string;
  wrapperClasses?: string;
  labelClasses?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  error?: string;
  info?: string;
};

export default function Select({
  id,
  name,
  label,
  placeholder,
  onChange,
  customClasses = "",
  wrapperClasses = "",
  labelClasses = "",
  options,
  value,
  error,
  info,
}: PropsType) {
  if (error) {
    customClasses += " border-red-500";
    customClasses += " text-red-500";
    labelClasses += " text-red-500";
  }

  return (
    <div className={`relative ${wrapperClasses}`}>
      <QuestionTitle
        label={label}
        name={name}
        cssClasses={labelClasses}
        info={info}
      />
      <div className="relative">
        <div className="absolute right-[12px] top-[16px] z-0">
          {getArrowIcon("#002733")}
        </div>
        <select
          id={id}
          name={name}
          placeholder={placeholder}
          className={`z-10 w-full grow resize-none appearance-none rounded-[4px] border-[1px] border-[#CECABA] bg-theme-white p-3 text-[13px] leading-[18px] text-dark-blue outline-0 focus:border-dark-blue focus:outline-none lg:p-[12px] ${customClasses}`}
          style={{ WebkitAppearance: "none", MozAppearance: "none" }}
          onChange={onChange}
          defaultValue={value !== null ? value : ""}
        >
          {placeholder && (
            <option disabled value="" className="italic text-[#CFCAB8]">
              {placeholder}
            </option>
          )}
          {options?.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        {error ? <ErrorFormMessage message={error} /> : null}
      </div>
    </div>
  );
}
