import React from "react";

import DocumentList from "../components/DocumentList";
import VaultHead from "../components/VaultHead";
import { RequireUserAuth } from "../context/UserContext";
import { getDocumentIcon } from "../services/icons";

function DocumentsPage() {
  return (
    <>
      <VaultHead
        title="dina dokument"
        icon={getDocumentIcon("#0C2632", "20px", "100%")}
      />
      <DocumentList />
    </>
  );
}

export default function DocumentsPageWrapper() {
  return (
    <RequireUserAuth>
      <DocumentsPage />
    </RequireUserAuth>
  );
}
