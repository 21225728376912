import React, { ReactNode } from "react";

type FlowHeadProps = {
  title: string;
  icon?: ReactNode;
};

export default function VaultHead({ title, icon }: FlowHeadProps) {
  return (
    <div className="mb-10 flex flex-row items-center gap-3">
      {icon}
      <div className="font-intermedium text-[12px] uppercase tracking-[0.2em] text-dark-blue">
        {title}
      </div>
    </div>
  );
}
