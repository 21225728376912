import React, { useEffect, useState } from "react";

import QuestionTitle from "../flow/QuestionTitle";

type PropsType = {
  startValue: number | string;
  onChange: (e: number) => void;
  minValue?: number;
  maxValue?: number;
  wrapperClasses?: string;
  percentage?: boolean;
  buttonSize?: number;
  allowAdd?: boolean;
  label?: string;
};

export default function Number({
  startValue,
  wrapperClasses = "",
  minValue,
  maxValue,
  percentage,
  buttonSize = 32,
  onChange,
  allowAdd,
  label,
}: PropsType) {
  const [num, setNum] = useState(0);
  const [enableMinus, setEnableMinus] = useState(true);
  const [enablePlus, setEnablePlus] = useState(true);
  const step = 1;

  useEffect(() => {
    if (!startValue) {
      return;
    }
    if (typeof startValue === "string") {
      setNum(parseInt(`+${startValue}`));
      return;
    }
    setNum(startValue);
  }, [startValue]);

  useEffect(() => {
    setEnablePlus(num !== maxValue);
    setEnableMinus(num !== minValue);
  }, [num]);

  useEffect(() => {
    if (allowAdd === undefined) {
      return;
    }
    setEnablePlus(allowAdd);
  }, [allowAdd, num]);

  const decreaseNumber = () => {
    if (!enableMinus) {
      return;
    }
    updatedValue(num - step);
  };

  const increaseNumber = () => {
    if (!enablePlus) {
      return;
    }
    updatedValue(num + step);
  };

  const updatedValue = (num: number) => {
    let newNum = num;
    if (maxValue !== undefined && newNum > maxValue) {
      newNum = maxValue;
    }
    if (minValue !== undefined && newNum < minValue) {
      newNum = minValue;
    }
    onChange(newNum);
    setNum(newNum);
  };

  return (
    <>
      {label && (
        <div className="mb-5">
          <QuestionTitle label={label} name="" />
        </div>
      )}
      <div
        className={`flex flex-row items-center justify-between gap-2 ${wrapperClasses}`}
        data-testid="number"
      >
        <div
          className={`flex shrink-0 items-center justify-center rounded-full text-white transition ${
            enableMinus
              ? "cursor-pointer bg-theme-primary2 hover:bg-theme-primary3"
              : "cursor-default bg-theme-primary4"
          }`}
          style={{ width: buttonSize, height: buttonSize }}
          onClick={decreaseNumber}
        >
          -
        </div>
        <div className="flex max-w-[84px] flex-row items-center gap-1 font-interbold text-[22px] italic leading-none text-theme-primary1">
          <input
            value={num}
            type="text"
            className="w-full rounded-[4px] border-[1px] border-theme-neutral3 py-1 px-2 text-center"
            onChange={(e) => {
              const v = e.currentTarget.value;
              updatedValue(v.length && !isNaN(parseInt(v)) ? parseInt(v) : 0);
            }}
            min="0"
            max="100"
            step={step}
            role="input"
          />
          {percentage && `%`}
        </div>

        <div
          className={`flex shrink-0 items-center justify-center rounded-full text-white transition ${
            enablePlus
              ? "cursor-pointer bg-theme-primary2 hover:bg-theme-primary3"
              : "cursor-default bg-theme-primary4"
          }`}
          style={{ width: buttonSize, height: buttonSize }}
          onClick={increaseNumber}
        >
          +
        </div>
      </div>
    </>
  );
}
