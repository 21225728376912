import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Checkout from "../components/Checkout";
import LoaderScreen from "../components/LoaderScreen";
import { useUserContext } from "../context/UserContext";
import { RequireUserAuth } from "../context/UserContext";
import { getPaymentClientSecret } from "../services/ida";
import { decodeBase64, encodeBase64 } from "../services/utils";

function PaymentPage() {
  const [loading, setLoading] = useState(false);
  const [paymentParams, setPaymentParams] = useState();
  const [token, setToken] = useState();
  const [price, setPrice] = useState();
  const { data } = useParams<{
    data: string;
  }>();
  const { user } = useUserContext();

  useEffect(() => {
    if (!data) {
      return;
    }

    try {
      const dataObj = JSON.parse(decodeBase64(data));
      if (!dataObj) {
        return;
      }

      setPaymentParams(dataObj);
    } catch (e) {
      console.log(e);
      return;
    }
  }, []);

  useEffect(() => {
    if (!paymentParams || !user.accessToken) {
      return;
    }

    const stripeSubscriptionId = paymentParams.s;

    setLoading(true);
    getPaymentClientSecret(
      user.accessToken,
      stripeSubscriptionId,
      paymentParams.p
    )
      .then((data) => {
        if (!data) {
          return;
        }
        const newSubscriptionId = data.subscriptionId;
        if (stripeSubscriptionId === newSubscriptionId) {
          setToken(data.paymentClientSecret);
          setPrice(data.orderTotalPrice);
        } else {
          const dt = encodeBase64({ s: newSubscriptionId, p: paymentParams.p });
          window.location.href = `/payment/${dt}`;
          return;
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  }, [paymentParams]);

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return <>{token && <Checkout clientSecret={token} price={price} />}</>;
}

export default function PaymentPageWrapper() {
  return (
    <RequireUserAuth>
      <PaymentPage />
    </RequireUserAuth>
  );
}
